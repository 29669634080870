import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { updateCampaignView } from "src/store/slices/campaignsSlice";
import { CampaignViewTypes } from "src/types";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  loadCampaignDataThunk,
  selectCurrentCampaign,
  selectCurrentCampaignView,
} from "../../../store/slices/campaignsSlice";
import {
  connectSocketThunk,
  disconnectSocketThunk,
  resetComputationMessages,
  setSocketShouldReconnect,
} from "../../../store/slices/computationMessagesSlice";
import {
  selectCurrentPhase,
  selectPhasesList,
  setPhases,
} from "../../../store/slices/phasesSlice";
import {
  selectCurrentStep,
  selectStepsList,
} from "../../../store/slices/stepsSlice";
import { AppDispatch } from "../../../store/store";

const useInitCampaignHook = () => {
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch: AppDispatch = useDispatch();
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentPhase = useSelector(selectCurrentPhase);
  const currentStep = useSelector(selectCurrentStep);
  const phases = useSelector(selectPhasesList);
  const steps = useSelector(selectStepsList);
  const view = useSelector(selectCurrentCampaignView);

  const handleViewChange = (newView: CampaignViewTypes) => {
    dispatch(updateCampaignView({ viewType: newView }));
    setSearchParams({ view: newView }, { replace: true });
  };

  useEffect(() => {
    const viewFromParams = searchParams.get("view");
    if (stepId && !viewFromParams) {
      const currentHash = window.location.hash;

      setSearchParams({ view }, { replace: true });
      window.location.hash = currentHash;
    }
  }, [view, stepId]);

  useEffect(() => {
    const getData = async (campaignId: number) => {
      try {
        const res = await dispatch(
          loadCampaignDataThunk({
            campaignId,
            companyIdFromUrl: companyId,
            companyId: companyIdValidated,
            phaseId: phaseId ? +phaseId : undefined,
            stepId: stepId ? +stepId : undefined,
          })
        );

        if ("error" in res) {
          messageApi.error(res.payload?.message);

          navigate(`/admin/campaigns`);
        } else {
          const navigatePath = res.payload;

          dispatch(setSocketShouldReconnect(true));
          dispatch(connectSocketThunk({ campaignId }));

          // Get view from query params or set default
          let viewFromParams = searchParams.get(
            "view"
          ) as CampaignViewTypes | null;
          if (
            !viewFromParams ||
            !Object.values(CampaignViewTypes).includes(viewFromParams)
          ) {
            viewFromParams = CampaignViewTypes.GRID; // Set default view
          }

          dispatch(updateCampaignView({ viewType: viewFromParams }));

          if (navigatePath) {
            navigate(
              `${navigatePath}?view=${viewFromParams}${window.location.hash}`,
              { replace: true }
            );
          } else {
            setSearchParams({ view: viewFromParams }, { replace: true });
          }
        }
      } catch (e) {
        console.error("get all data ERR:", e);
      }
    };

    if (campaignId) {
      getData(+campaignId);
    }

    return () => {
      dispatch(disconnectSocketThunk());
      dispatch(resetComputationMessages());
    };
  }, [campaignId]);

  const dispatchSetPhases = (phases: any) => {
    dispatch(setPhases(phases));
  };

  return {
    currentCampaign,
    currentPhase,
    currentStep,
    phases,
    steps,
    companyId: companyIdValidated,
    setPhases: dispatchSetPhases,
    view,
    handleViewChange,
  };
};

export default useInitCampaignHook;
