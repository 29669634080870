import { FileOutlined, LinkOutlined } from "@ant-design/icons";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/16/solid";
import { Checkbox, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import { TAsset } from "../../../globalTypes";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { DeleteIcon, GetLinkIcon, GlobeIcon, Icons } from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { Button } from "../../common/ui/button";
import { TableCell, TableRow } from "../../common/ui/table";

type Props = {
  asset: TAsset;
  onGetLink: (ref: string) => Promise<void>;
  onDownloadFile: (ref: string) => Promise<void>;
  onApproveAsset: (id: number, approved: boolean) => Promise<void>;
  onApprovePublish: (asset: TAsset, approved: boolean) => Promise<void>;
  onPublishAsset: (id: number) => Promise<void>;
  onEditAsset: (asset: TAsset) => void;
  onDeleteAsset: (id: number, removeFiles?: boolean) => Promise<void>;
};

export const getPublicPath = (asset: TAsset) => {
  const { ref } = asset;
  const path = ref?.split("/").slice(6).join("/");

  return `/${path}`;
};

const AssetListItem: FC<Props> = ({
  asset,
  onGetLink,
  onDownloadFile,
  onApproveAsset,
  onApprovePublish,
  onEditAsset,
  onPublishAsset,
  onDeleteAsset,
}) => {
  const {
    title,
    ref,
    id,
    approved,
    publishToMicroSite,
    publishedDate,
    microSiteTargetFolder,
    type,
  } = asset;

  const { isRegularUser } = useCurrentUser();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const publishedDateValidated = publishedDate
    ? `Published: ${new Date(publishedDate).toLocaleString()}`
    : "Not published yet";

  const onLink = async (ref: string) => {
    try {
      await navigator.clipboard.writeText(ref);

      messageApi.success("The link has been copied to the clipboard!");
    } catch (e) {
      messageApi.error("Failed to copy the link");
      console.error("Copy link ERROR:", e);
    }
  };

  const publicPath = getPublicPath(asset);

  const menuItems = [
    publishedDate && {
      key: "get_link",
      label: "Get Link",
      onClick: () => onGetLink(publicPath),
      icon: GetLinkIcon,
    },
    !isRegularUser &&
      ref && {
        key: "get_aws_link",
        label: "Get Public AWS Link",
        onClick: () => onLink(ref),
        icon: GlobeAsiaAustraliaIcon,
      },
    !isRegularUser && {
      key: "publish",
      label: publishedDate ? "Republish" : "Publish",
      onClick: () => onPublishAsset(id),
      icon: GlobeIcon,
    },
    !isRegularUser &&
      type !== "GENERATED" && {
        key: "edit",
        label: "Edit",
        onClick: () => onEditAsset(asset),
        icon: Icons.edit,
      },
    !isRegularUser && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <TableRow
      key={id}
      className="cursor-pointer relative text-[#475569] font-sans h-10"
    >
      <TableCell className="w-[400px] flex gap-[8px]">
        {ref ? (
          //file from storage
          ref.startsWith("/") ? (
            <>
              <Tooltip title="Campaign file link">
                <FileOutlined />
              </Tooltip>
              <Typography.Link
                className="text-[18px]"
                onClick={async (e) => {
                  e.preventDefault();
                  await onDownloadFile(ref);
                }}
              >
                {title}
              </Typography.Link>
            </>
          ) : (
            <>
              <Tooltip title="External link">
                <LinkOutlined />
              </Tooltip>
              <Typography.Link
                href={ref.startsWith("http") ? ref : `http://${ref}`}
                target="_blank"
                className="text-[18px]"
              >
                {title}
              </Typography.Link>
            </>
          )
        ) : (
          <div className="text-orange-600 ">
            <span className="text-[18px] mr-[6px]">{title}</span>
            <span>(the link is lost, update it manually)</span>
          </div>
        )}
      </TableCell>
      <TableCell
        className={clsx("w-[400px]", {
          "opacity-30": !microSiteTargetFolder,
        })}
      >
        {microSiteTargetFolder || MICROSITE_INPUT_PLACEHOLDER}
      </TableCell>
      <TableCell className="w-[150px]">
        <Checkbox
          disabled={!ref}
          checked={approved}
          onChange={(e) => onApproveAsset(id, e.target.checked)}
        />
      </TableCell>
      <TableCell className="w-[150px]">
        <Checkbox
          className="mr-[10px]"
          disabled={!ref || !approved}
          checked={publishToMicroSite}
          onChange={(e) => onApprovePublish(asset, e.target.checked)}
        />
      </TableCell>
      <TableCell className="w-[125px]">
        <span className="text-[10px]">{publishedDateValidated}</span>
      </TableCell>
      <TableCell
        className="relative w-[25px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end items-center">
          <MenuDropdown
            items={menuItems}
            dropdownClassName="w-[190px]"
            align="end"
          >
            <Button variant={"ghostPrimary"} className="size-[40px] p-2">
              <Icons.MoreDots className="size-[24px]" />
            </Button>
          </MenuDropdown>
        </div>
      </TableCell>
      {isDeleteModalOpen && (
        <DeleteWithFilesModal
          isModalOpen={isDeleteModalOpen}
          entity="Asset"
          title={title}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={(removeFiles) => onDeleteAsset(id, removeFiles)}
        />
      )}
    </TableRow>
  );
};

export default AssetListItem;
