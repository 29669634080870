import { Button } from "antd";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { executeCcVarApi, validateCcVarApi } from "../../api/cc-variables.api";
import useConfirm from "../../hooks/useConfirm";
import { selectMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";

type Props = {
  campaignId: number;
  stepId: number;
  ccItemKey: string;
  resultOvr: string | null;
};

const PaneExecuteButton: FC<Props> = ({
  campaignId,
  ccItemKey,
  stepId,
  resultOvr,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const confirm = useConfirm();
  const messageApi = useSelector(selectMessageApi);
  const { companyId } = useParams();
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;

  const executeItem = async () => {
    try {
      setIsFetching(true);

      try {
        const {data: isExecuting} = await validateCcVarApi(campaignId);

        if (!isExecuting) {
          await executeCcVarApi(
            campaignId,
            ccItemKey,
            stepId,
            companyIdValidated
          );
          messageApi.success("Execution started successfully");
        } else {
          messageApi.error(
            "There are some active computation elements in progress, please wait before execute"
          );
        }
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onExecute = () => {
    confirm({
      action: executeItem,
      title: "Execute Item",
      content: resultOvr ? (
        <span>
          <strong className="text-orange-600">
            Your overwritten content will be reset.
          </strong>
          <br />
          Are you sure you want to continue?
        </span>
      ) : undefined,
    });
  };

  return (
    <Button size="small" loading={isFetching} onClick={onExecute}>
      Execute
    </Button>
  );
};

export default PaneExecuteButton;
