import { LockOutlined, PlusOutlined } from "@ant-design/icons";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { Form, Modal, Tooltip, Typography } from "antd";
import { FC, Fragment, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabsCarousel from "src/components/common/TabsCarousel";
import { Button } from "src/components/common/ui/button";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { selectCurrentCampaignView } from "src/store/slices/campaignsSlice";
import { CampaignViewTypes } from "src/types";
import { calcNewSeqDnd } from "src/utils";
import { TPhase } from "../../../globalTypes";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  TStep,
  getSteps,
  saveStepThunk,
  setSteps,
  updateStepSeqThunk,
  updateStepThunk,
} from "../../../store/slices/stepsSlice";
import { AppDispatch } from "../../../store/store";
import { STEP_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { getLastSequence } from "../../../utils/cm.utils";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { getStepNameValidationRule } from "../../../utils/validators";
import StepModal from "../../common/modals/StepModal/StepModal";
import Step from "../Step/Step";

export type TStepFormValues = {
  name: string;
  hidden: boolean;
  microSiteContextFolder: null | string;
  classes: string[];
};

type Props = {
  currentPhase: TPhase | null;
  companyId: number | undefined;
  campaignId: number;
};

const CampaignSteps: FC<Props> = ({ currentPhase, companyId, campaignId }) => {
  const steps = getSteps();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editStep, setEditStep] = useState<TStep | null>(null);
  const [form] = Form.useForm<TStepFormValues>();
  const [sequence, setSequence] = useState<number | null>(null);
  const { isGlobalAdmin, hasStepCreateRole, hasStepListRole, hasStepEditRole } =
    useCurrentUser();
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const currentCampaignView = useSelector(selectCurrentCampaignView);
  const navigate = useNavigate();

  const stepNameValidationRule = getStepNameValidationRule({
    editStepName: editStep?.name,
    steps,
  });

  async function onCreateStep(values: TStepFormValues) {
    try {
      if (sequence === null) {
        messageApi.error("Sequence is null");
        return;
      }

      const res = await dispatch(
        saveStepThunk({
          phaseId: currentPhase!.id,
          stepName: values.name,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
          classes: values.classes,
          sequence,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        const { phaseId, stepId } = res.payload;
        const companyIdForUrl = companyId === undefined ? "global" : companyId;
        const path = `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}`;

        navigate(path, { replace: true });
        onCancel();
        messageApi.success("The phase has been successfully created");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to save the step:", e);
    }
  }

  const onEditStep = async (values: TStepFormValues) => {
    const isMicroSiteContextFolderChanged =
      values.microSiteContextFolder !== editStep?.microSiteContextFolder;

    const isStepNameChanged = values.name !== editStep?.name;

    const updateStep = async (republish: boolean) => {
      try {
        await dispatch(
          updateStepThunk({
            step: {
              ...editStep!,
              ...values,
            },
            republish,
          })
        ).unwrap();

        onCancel();
      } catch (e: any) {
        messageApi.error(e?.message);
        console.error("An error occurred while trying to update the step:", e);
      }
    };

    const confirmationMessage = getConfirmationMessage(
      isMicroSiteContextFolderChanged,
      isStepNameChanged,
      "Step Name"
    );

    if (confirmationMessage) {
      Modal.confirm({
        title: "Confirm Step Update",
        content: confirmationMessage,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => updateStep(true),
      });
    } else {
      await updateStep(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setEditStep(null);
    setSequence(null);
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TStepFormValues) => {
      editStep ? await onEditStep(values) : await onCreateStep(values);
    },
  });

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex },
    } = result;

    if (sourceIndex === destinationIndex) return;

    const newDraggedItemSeq = calcNewSeqDnd(result, steps, STEP_SEQUENCE_STEP)!;

    const draggedItem = { ...steps[sourceIndex], seq: newDraggedItemSeq };

    try {
      await dispatch(updateStepSeqThunk(draggedItem));

      const copy = steps.slice();
      const [removed] = copy.splice(result.source.index, 1);

      copy.splice(result.destination.index, 0, removed);

      dispatch(setSteps(copy));
    } catch (e) {
      console.error("An error occurred while trying to drag the phase:", e);
    }
  };

  const isCampaignViewDoc = currentCampaignView === CampaignViewTypes.DOC;

  const hasStepDocTemplate = (step: TStep) =>
    isCampaignViewDoc ? !!step.documentTemplateId : false;

  return (
    <div>
      <TabsCarousel
        className="flex clear-start bg-indigo-50 rounded-br-[8px] items-center overflow-scroll"
        rightButtonClassName="rounded-br-[8px]"
      >
        {hasStepListRole && (
          <div className="flex">
            {currentPhase ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, _snap) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="flex"
                    >
                      {steps.map((step, index) => {
                        if (!isGlobalAdmin && step.hidden)
                          return <Fragment key={step.id} />;

                        return (
                          <Draggable
                            isDragDisabled={!hasStepEditRole}
                            key={step.id}
                            draggableId={String(step.id)}
                            index={index}
                          >
                            {(provided, _snap) => (
                              <div
                                style={{ position: "relative" }}
                                className="relative"
                                key={step.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Step
                                  prevStepSeq={steps[index - 1]?.seq}
                                  nextStepSeq={steps[index + 1]?.seq}
                                  step={step}
                                  form={form}
                                  stepIndex={index}
                                  setIsModalOpen={setIsModalOpen}
                                  setEditStep={setEditStep}
                                  setSequence={setSequence}
                                  stepNameValidationRule={
                                    stepNameValidationRule
                                  }
                                  phaseId={currentPhase.id}
                                  companyId={companyId}
                                  campaignId={campaignId}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                                {isGlobalAdmin && step.hidden && (
                                  <Tooltip
                                    title={
                                      <span>
                                        This Step is hidden from users.
                                        <br />
                                        To switch the state, go to the Edit Step
                                        modal.
                                      </span>
                                    }
                                  >
                                    <LockOutlined className="text-slate-600 absolute top-[5px] left-[5px]" />
                                  </Tooltip>
                                )}
                                {hasStepDocTemplate(step) && (
                                  <Tooltip
                                    title={
                                      <span>
                                        This Step has a document template.
                                      </span>
                                    }
                                  >
                                    <DocumentIcon className="text-slate-600 absolute bottom-[6px] left-[6px] size-3" />
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Typography.Text>
                There are no steps in this phase yet
              </Typography.Text>
            )}
          </div>
        )}
        {hasStepCreateRole && (
          <Button
            disabled={!currentPhase}
            className="border-transparent bg-transparent border-b-2 hover:bg-primary-foreground hover:border-primary-foreground rounded-none h-[48px]"
            onClick={() => {
              const sequence = getLastSequence(steps, STEP_SEQUENCE_STEP);

              setIsModalOpen(true);
              setSequence(sequence);
            }}
            variant="primaryOutline"
            icon={PlusOutlined}
          >
            Add Step
          </Button>
        )}
      </TabsCarousel>
      {isModalOpen && (
        <StepModal
          onSubmitForm={submitForm}
          onCancel={onCancel}
          form={form}
          isModalOpen={isModalOpen}
          editStep={editStep}
          stepNameValidationRule={stepNameValidationRule}
        />
      )}
    </div>
  );
};

export default CampaignSteps;
