import React from "react";
import { useSelector } from "react-redux";
import { TFileTreeItem, TStorageFileItem } from "../../../../globalTypes";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";
import processFilesData from "../utils/processFilesData";

type Props = {
  selectedItem: TFileTreeItem;
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  upload: (formData: FormData) => Promise<TStorageFileItem>;
};

const useUpload = ({ selectedItem, setTreeData, upload }: Props) => {
  const { fileName, level, expanded } = selectedItem;
  const messageApi = useSelector(selectMessageApi);

  return async (file: File) => {
    try {
      const formData = new FormData();

      formData.append("path", fileName);
      formData.append("file", file);

      const newFileData = await upload(formData);

      if (expanded) {
        setTreeData((prevState) => {
          const newTreeData = processFilesData({
            data: [newFileData],
            parentPath: fileName,
            level: level - 1,
          });

          return [...prevState, ...newTreeData];
        });
      }

      messageApi.success("The file was uploaded successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };
};

export default useUpload;
