import { Input, Modal, Spin } from "antd";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  TCampaign,
  editCampaignThunk,
} from "../../../store/slices/campaignsSlice";
import { AppDispatch } from "../../../store/store";

type PropsType = {
  currentCampaign: TCampaign;
  hasPermissions: boolean;
  companyId: number | undefined;
};

const EditCampaignName: FC<PropsType> = (props) => {
  const { currentCampaign, hasPermissions, companyId } = props;
  const campaignName = currentCampaign.name;
  const messageApi = getMessageApi();
  const dispatch: AppDispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedCampaignName, setEditedCampaignName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [validationErr, setValidationErr] = useState("");

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const trimmed = newName ? newName.trim() : "";
    let err = "";

    if (trimmed.length < 4) {
      err = "The name must not be less than 4 characters!";
    }

    if (trimmed.length > 255) {
      err = "The name must contain no more than 255 characters!";
    }

    if (/[\\/]/.test(trimmed)) {
      err = "The name must not contain slashes!";
    }

    setValidationErr(err);
    setEditedCampaignName(newName);
  };

  const saveName = async () => {
    if (!validationErr) {
      if (editedCampaignName !== campaignName) {
        setIsFetching(true);

        try {
          const newCampaignData: TCampaign = {
            ...currentCampaign,
            name: editedCampaignName.trim().replace(/ +/g, " "),
          };

          await dispatch(
            editCampaignThunk({
              campaign: newCampaignData,
              companyId,
              republish: true,
            })
          ).unwrap();

          messageApi.success("The campaign has been successfully renamed");
        } catch (e: any) {
          messageApi.error(e?.message);
          console.error(
            "An error occurred while trying to rename the campaign:",
            e
          );
        } finally {
          setIsFetching(false);
        }
      }
    } else {
      setEditedCampaignName(campaignName);
    }

    setIsEditMode(false);
    setValidationErr("");
  };

  const onSaveName = async () => {
    Modal.confirm({
      title: "Confirm campaign name change",
      content: "Are you sure you want to change the campaign name?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: saveName,
      onCancel: () => setIsEditMode(false),
    });
  };

  const onOpenEditMode = () => {
    setEditedCampaignName(campaignName);
    setIsEditMode(true);
  };

  return (
    <div className="w-full relative">
      <div
        style={{
          opacity: isFetching ? 0.05 : 1,
          pointerEvents: isFetching ? "none" : "auto",
        }}
      >
        {isEditMode ? (
          <>
            {!!validationErr && (
              <span className="absolute left-0 top-[-20px] text-[10px] text-red-600">
                {`${validationErr} The result will not be saved.`}
              </span>
            )}
            <Input
              value={editedCampaignName}
              autoFocus
              className="w-[500px]"
              size="middle"
              onChange={onNameInputChange}
              onBlur={onSaveName}
            />
          </>
        ) : (
          <span
            className="text-slate-900 text-xl font-semibold font-sans leading-tight"
            onClick={hasPermissions ? onOpenEditMode : undefined}
          >
            {campaignName}
          </span>
        )}
      </div>
      <Spin
        spinning={isFetching}
        className="absolute left-[20px] top-1/2 transform -translate-y-1/2"
      />
    </div>
  );
};

export default EditCampaignName;
