import { ComputationType, ExecutionType } from "../constants";
import {
  TComputationStatus,
  TPhase,
  TSocketMsg,
  TSocketMsgCampaignError,
  TSocketMsgCIError,
  TSocketMsgCIUpdate,
  TSocketMsgComputationStatus,
  TSocketMsgMicrositeDeploy,
  TSocketMsgMicrositeDeployError,
  TSocketMsgPhaseError,
  TSocketMsgPromptPostProcessingResult,
  TSocketMsgStepError,
  TSocketMsgType,
} from "../globalTypes";
import { TCcVariable } from "../store/slices/ccVariablesSlice";
import { TEventMessagesMap } from "../store/slices/computationMessagesSlice";
import { TStepPathMap } from "../store/slices/stepsSlice";
import { getProgressNumber, toLocalTime } from "./cm.utils";

type TProcessEventMessagesProps = {
  messages: TSocketMsg<TSocketMsgType>[];
  stepPathMap: TStepPathMap;
  shouldUpdateCurrentStepCCItemsInGrid: boolean;
  currentStepId: number | undefined;
  phaseList: TPhase[];
  lastExecutionTimestamp: number | null;
};

type TProcessEventMessagesResponse = {
  eventMessagesMap: TEventMessagesMap;
  itemsToUpdate: TCcVariable[];
  computationStatus: TComputationStatus | null;
  computationProgress: number | null;
  computationType: ComputationType | null;
  executionType: ExecutionType | null;
  executionTimestamp: number | null;
  shouldResetMessages: boolean;
};

const processEventMessages = ({
  messages,
  stepPathMap,
  shouldUpdateCurrentStepCCItemsInGrid,
  currentStepId,
  phaseList,
  lastExecutionTimestamp,
}: TProcessEventMessagesProps): TProcessEventMessagesResponse => {
  let shouldResetMessages = false; // if true, reset all previously saved messages
  let eventMessagesMap: TEventMessagesMap = {}; // set in all types
  const itemsToUpdate: TCcVariable[] = []; // set in CI_UPDATED and CI_ERROR type
  let computationStatus: TComputationStatus | null = null; // set in COMPUTATION_STATUS type
  let computationProgress: number | null = null; // set in COMPUTATION_STATUS type
  let computationType: ComputationType | null = null; // set in COMPUTATION_STATUS type
  let executionType: ExecutionType | null = null; // set in COMPUTATION_STATUS type
  let executionTimestamp: number | null = null; // set in COMPUTATION_STATUS type

  messages.forEach((message, index) => {
    const { type, timestamp } = message;
    const localTime = toLocalTime(timestamp);

    switch (type) {
      case "CI_UPDATED": {
        const { item, computationTime } = message as TSocketMsgCIUpdate;
        const { key, stepId } = item.id;
        const eventMessageKey = `${type}_${stepId}_${key}`;

        //update only visible items in the grid (if certain step opened)
        if (shouldUpdateCurrentStepCCItemsInGrid && currentStepId === stepId) {
          itemsToUpdate.push(item);
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: stepPathMap[stepId] + key + ` (${computationTime}ms)`,
          status: item.state,
          item,
        };
        break;
      }
      case "CI_ERROR": {
        const { key, stepId, errorMessage, item } =
          message as TSocketMsgCIError;
        const eventMessageKey = `${type}_${stepId}_${key}`;

        //update only visible items in the grid (if certain step opened)
        if (shouldUpdateCurrentStepCCItemsInGrid && currentStepId === stepId) {
          itemsToUpdate.push(item);
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: stepPathMap[stepId] + key + " " + errorMessage,
          status: item.state,
          item,
        };
        break;
      }
      case "STEP_ERROR": {
        const { stepId, errorMessage } = message as TSocketMsgStepError;
        const noStepMessage = `[The step with ID: ${stepId} is not loaded. Maybe someone created this step, but you don't have an updated state]`;
        const eventMessageKey = `${type}_${stepId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: (stepPathMap[stepId] || noStepMessage) + errorMessage,
          status: "error",
        };
        break;
      }
      case "PHASE_ERROR": {
        const { phaseId, errorMessage } = message as TSocketMsgPhaseError;
        const errorPhase = phaseList.find((phase) => phase.id === phaseId);
        const noPhaseMessage = `[The phase with ID: ${phaseId} is not loaded. Maybe someone created this phase, but you don't have an updated state]`;
        const errorMsgPhase = `${errorPhase ? errorPhase.name : noPhaseMessage}/${errorMessage}`;
        const eventMessageKey = `${type}_${phaseId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: errorMsgPhase,
          status: "error",
        };
        break;
      }
      case "CAMPAIGN_ERROR": {
        const { errorMessage, campaignId } = message as TSocketMsgCampaignError;
        const eventMessageKey = `${type}_${campaignId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: errorMessage,
          status: "error",
        };
        break;
      }
      case "COMPUTATION_STATUS": {
        const {
          total,
          onhold,
          state,
          computationType: compType,
          executionType: execType,
          executionTimestamp: execTimestamp,
        } = message as TSocketMsgComputationStatus;

        computationProgress = getProgressNumber(total, onhold);
        computationStatus = state;
        computationType = compType;
        executionType = execType;
        executionTimestamp = execTimestamp;

        // clear previous messages if executionTimestamp is different from the last one
        if (
          lastExecutionTimestamp &&
          lastExecutionTimestamp !== executionTimestamp
        ) {
          console.log("Clearing messages...", lastExecutionTimestamp, "=====>>>>>>>>", executionTimestamp, message);
          eventMessagesMap = {};
          shouldResetMessages = true;
        }

        break;
      }

      case "POST_PROCESSING": {
        const {
          postProcessingResultMessage,
          item: { id },
        } = message as TSocketMsgPromptPostProcessingResult;
        const { stepId, key } = id;
        const eventMessageKey = `${type}_${stepId}_${key}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: `${stepPathMap[stepId] + key} 
                  
                  ${postProcessingResultMessage}`,
          status: "finished",
        };
        break;
      }

      case "MICROSITE_DEPLOY": {
        const {
          asset: { title, stepId },
        } = message as TSocketMsgMicrositeDeploy;
        const eventMessageKey = `${type}_${stepId}_${title}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: stepPathMap[stepId] + title,
          status: "finished",
        };
        break;
      }

      case "DEPLOY_ERROR": {
        const { stepId, errorMessage, phaseId } =
          message as TSocketMsgMicrositeDeployError;
        const eventMessageKey = `${type}_${phaseId}_${stepId}`;

        let errorKey = errorMessage;

        if (stepId) {
          errorKey = stepPathMap[stepId] + errorMessage;
        } else if (phaseId) {
          const errorPhase = phaseList.find((phase) => phase.id === phaseId);
          errorKey = `${errorPhase?.name}/${errorMessage}`;
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: errorKey,
          status: "error",
        };
        break;
      }

      default: {
        const eventMessageKey = `${type}_${index}_${localTime}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: `${index}__Unhandled message type__${localTime}`,
        };
        console.warn("Unhandled message type", message);
        break;
      }
    }
  });

  return {
    eventMessagesMap,
    itemsToUpdate,
    computationStatus,
    computationProgress,
    computationType,
    executionType,
    executionTimestamp,
    shouldResetMessages,
  };
};

export default processEventMessages;
