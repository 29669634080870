import { CheckOutlined } from "@ant-design/icons";
import { Alert, Flex, Modal, Spin, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

type TReleaseData = {
  name: string;
  time: string;
  version: string;
  artifact: string;
  group: string;
};

type TReleasesPopupProps = {
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpened: boolean;
};

const messagedData = [
  {
    date: "20 June",
    changes: [
      "[VEP-959] Add State Property",
      "[VEP-944] Post Processing Debug Event Log",
      `[VEP-953] UI: add "Enable HTTPS" property`,
      "[VEP-945] UI: Add checkbox remove storage files",
      "[VEP-897] Copy Step Function rework",
      "[VEP-946] ErrorBoundary added",
      "[VEP-855] Added Post Processing to prompt rows",
      "[VEP-932] Fix the behavior of the company selector in the campaign list",
      "[VEP-911] 404 Not found is displayed in the body of page",
      "[VEP-922] Disable DnD by role",
      "[VEP-912] Hide grid parameters if not global admin",
      "[VEP-916] Add new Phase/Step roles",
      "[VEP-915] Process cc item keys with full path",
    ],
  },
  {
    date: "7 June",
    changes: [
      "[VEP-889] Model Config. Field Read Timeout. Add a tooltip with time unit",
      "[VEP-891] Add got to definition feature",
      "[VEP-892] Disable inactive node action",
      "[VEP-863] Add Clone item title",
      "[VEP-903] Can't edit a users email",
      "[VEP-907] Dependency graph: Input/Target loading state",
      "[VEP-901] Set the timeout value equal 0",
      "[VEP-908] Graph. The _copy part has disappeared from the target",
    ],
  },
  {
    date: "6 June",
    changes: [
      "[VEP-861] Add the 'waiting_computation' icon to the grid",
      "[VEP-811] Rename label in modal window Upload Template Package",
      "[VEP-830] Change Company for Global Campaign, Refactor hooks",
      "[VEP-848] Separated the text when delete company and delete user",
      "[VEP-828] Add cc item graph",
      "[VEP-835] AI Model Selector",
      "[VEP-859] Redevelop dynaforms, adjust env vars form",
      "[VEP-838] Graph adjustments",
      "[VEP-835] AI Models Table, Create, Edit",
      "[VEP-866] Edit cc item permissions",
      "[VEP-819] Add validations file",
      "[VEP-863] Process null value in depends on selector",
      "[VEP-835] Reset Model Name, after Service Provider is changed",
      "[VEP-868] Part 2: Popup to remove Model Config",
      "[VEP-870] Multiple user list requests",
      "[VEP-872] Graph target input behaviour",
      "[VEP-880] Set graph/edit pane height",
      "[VEP-873] Model Configs, Validations, System Default Remove",
      "[VEP-888] Colorize graph markers",
    ],
  },
  {
    date: "24 May",
    changes: [
      "[VEP-789] Remove elevate name",
      "[VEP-823] Add temporary redirect",
      "[VEP-791] Testing events optimizations",
      "[VEP-826] Add a new event error",
      "[VEP-818] Close dropdown when Modal widnow is Opened on Phases/Steps",
      "[VEP-827] Events edits: icon, sorting",
      "[VEP-829] Fix insert after",
      "[No Ticket] Preline in Content Column",
      "[No Ticket] Refactor Empty List Behavior on Assets",
    ],
  },
  {
    date: "16 May",
    changes: [
      "[VEP-806] Template filters fix",
      "[VEP-805] Fix redirect link",
      "[VEP-747] More validations on adding Asset",
      "[VEP-747] Micro Site Context Folder path validation",
      "[VEP-777] Resetting the state filter on the templates page does not work",
      "[VEP-750] Edit step data preview",
      "[VEP-800] Create folder adjustments",
      "[VEP-799] Add a minimum fixed distance between Micro Site сampaignViewStorage and SFTP Users",
      "[VEP-801] Edit storage panel z-index",
      "[VEP-804] Remove Addon Before",
      "[VEP-803] Companies detail: Closing drawer after edit-error fixed",
      "[VEP-809] Required Microsite Field",
      "[VEP-810] Fix locks animation",
      "[VEP-812] Add zip file limit",
    ],
  },
  {
    date: "14 May",
    changes: [
      "[VEP-763] Update tree after upload",
      "[VEP-759] UI: Delete a file/folder",
      "[VEP-772] Add editing text files",
      "[VEP-757] Upload Template Package Action",
      "[VEP-777] Fix Template Search Page",
      "[VEP-788] Remove redirect after saving a template",
      "[VEP-771] Add reusable tabs component",
    ],
  },
  {
    date: "9 May",
    changes: [
      "[VEP-756] Add select storage item feature",
      "[VEP-733] Recalculate total companies after delete a company",
      "[VEP-657] Add context path from microsite context path",
      "[VEP-734] Recalculate total users after delete a user",
      "[VEP-761] Remove the ability to delete the root directory",
    ],
  },
  {
    date: "8 May",
    changes: [
      "[VEP-732] Closing drawer by error fixed",
      "[VEP-756] Add select file/folder feature",
    ],
  },
  {
    date: "7 May",
    changes: [
      "[VEP-748] Edit content and recompute downstream dependencies",
      "[HotFeature] Include Campaign Structure checkbox on Template Settings",
      "[VEP-731] Clear the field image when create a new company",
      "[VEP-713] Dynamic forms depends on support",
    ],
  },
  {
    date: "6 May",
    changes: ["[VEP-718] DnD for Phases & Steps"],
  },
  {
    date: "3 May",
    changes: [
      "[VEP-743][VEP-745] Clone Campaign, Edit Asset",
      "[VEP-742][VEP-744] Ellipsis, Admin Role to RUD (C - in progress)",
    ],
  },
  {
    date: "2 May",
    changes: [
      "[VEP-730] UI: Update forms in campaign actions (labels, inputs)",
      "[VEP-726] Update assets list",
    ],
  },
  {
    date: "30 April",
    changes: [
      "[VEP-728] Update step classes display",
      "[VEP-722-723] Hide publish to microsite action, update asset in the list after editing",
    ],
  },
  {
    date: "29 April",
    changes: [
      "[VEP-714] Create common table list component",
      "[VEP-711] Company details menu replaced",
    ],
  },
  {
    date: "26 April",
    changes: [
      "[VEP-717] Change api: rename cc item key",
      "[VEP-715] Add reload cc items",
    ],
  },
  {
    date: "25 April",
    changes: [
      "[VEP-654] Templates List filtering by state dropdown, sorting by name A-z",
      "[VEP-695] Add checkbox include campaign structure on the templates grid page",
      "[VEP-668] Redesign customer site",
      "[VEP-699] Max Length for Microsite subdomain",
      "[VEP-708] Modal view keys",
    ],
  },
  {
    date: "24 April",
    changes: [
      "[VEP-700] Change template preview in steps (new options api)",
      "[VEP-667] Cannot copy-to a global company/campaign",
      "[VEP-696] Customer Site. Display slash if subdomain is specified",
      "[VEP-709] Templates. Redirect to settings if the fields are not filled in",
      "[VEP-693] Templates. Need the splitter which allows the resizing",
    ],
  },
  {
    date: "23 April",
    changes: [
      "[VEP-690] Add company options",
      "[VEP-661] UI: added users pagination",
      "[VEP-666] Redevelop company drawer, company main details",
      "[VEP-658][VEP-657] Site Context Display & Validation",
      "[VEP-697] Reduce the maximum username length to 150",
    ],
  },
  {
    date: "19 April",
    changes: [
      "[VEP-662] Fix delete cc item api",
      "[VEP-660] Create and add another functionality added",
      "[VEP-659] Company pagination",
      "[VEP-608-609] UI fixes",
      "[VEP-645] Don't show input in confirmation when user wants to delete autogenerated asset",
    ],
  },
  {
    date: "18 April",
    changes: [
      "[VEP-647] Template List: Delete, MediaType column added (#243)\n",
      "[VEP-656] Template Editor: Setting added",
      "[VEP-653] UI update: Users List",
    ],
  },
  {
    date: "17 April",
    changes: [
      "[VEP-613] UI update: Add company drawer component",
      "[VEP-650] UI update: HTML editor",
      "[VEP-647] UI update: Template List",
      "[VEP-655] UI update: XSLT editor",
    ],
  },
  {
    date: "16 April",
    changes: [
      "[VEP-641] UI: Additional request to reload computation results after overriding result",
      "[VEP-643] UI: Add the available templates(selector) in the asset grid element",
      "[VEP-646] UI: Add class assignment to a step (draft version)",
    ],
  },
  {
    date: "15 April",
    changes: [
      "[VEP-616] UI update: Add user drawer",
      "[VEP-639] UI: Create/edit template Pt.1 - basic functionality",
      "[VEP-638] Create a utility to trim the spaces before submitting the form",
    ],
  },
  {
    date: "10 April",
    changes: [
      "[VEP-630] Fix campaign pagination issue",
      "[VEP-633] Error when trying to delete an automatically generated asset",
      "[VEP-631] File сampaignViewStorage: The form and upload button move to the right",
    ],
  },
  {
    date: "8 April",
    changes: [
      "[VEP-618] Update delete step/phase modal (remove storage files)",
      "[VEP-515] Editing the grid item, phase, step menu",
    ],
  },
  {
    date: "5 April",
    changes: [
      "[VEP-588] UI: update Export Campaign modal",
      "[VEP-610] Replace the company selector in create campaign modal ",
      "[VEP-587] Delete asset confirm",
      "[VEP-615] Update the api with new parameters",
      "[VEP-612] Process models list, redevelop step id selector",
      "[VEP-603] Add pagination to redesigned Company List page (global admin only for the moment)",
    ],
  },
  {
    date: "4 April",
    changes: [
      "[VEP-585] Added loading models of AI providers",
      "[VEP-569][VEP-600] Microsite & SFTP Users issues",
      "[VEP-593] New UI for Company List",
      "[VEP-596] Add get users api (pagination)",
    ],
  },
  {
    date: "3 April",
    changes: [
      "[VEP-556] Fixed validation of the company name",
      "[VEP-590] Remove old code(node), remove unused deps",
      "[VEP-591] Replace the company selector in the campaign import popup",
      "[VEP-595] Hide error message in storage",
    ],
  },
  {
    date: "2 April",
    changes: [
      "[VEP-589] Fix dynamic form items order",
      "[HOTFIX] Fix Create SFTP User the bug with the modal window",
      "[VEP-586] Fix modal and message context",
    ],
  },
  {
    date: "1 April",
    changes: [
      "[VEP-571] No error message when a client doesn't have a user login setup",
      "[VEP-557] Add validation for max value of field 'Site Context'",
    ],
  },
  {
    date: "29 March",
    changes: [
      "Update computation prompt (VEP-558)",
      "Copy to: Refinement of the component, adding items loading by part of the name (VEP-549)",
    ],
  },
  {
    date: "28 March",
    changes: [
      "Change Campaign Company(VEP-541)",
      "Copy to feature - phase/step (working but need to refactor/redevelop)(VEP-549)",
      "Update +Add Asset button UI (VEP-554)",
      "Add stub when users no in company (VEP-555)",
      "Edit validation of the phase and step name (VEP-552)",
      "Company Users should not be able to execute the full campaign (VEP-553)",
      "Edit microSiteTargetFolder tooltip (VEP-537)",
      "Add minimum validation for the company name of 2 characters (VEP-556)",
      "Add more button for dropdowns (VEP-515)",
      "Form SFTP. Block the button and do not display the loader when clicking on the button (VEP-563)",
    ],
  },
  {
    date: "27 March",
    changes: [
      "Edit validation of the phase and step name (VEP-552)",
      "Company Users should not be able to execute the full campaign (VEP-553)",
      "Refactor DELETE request on frontend for microsite (VEP-551)",
    ],
  },
  {
    date: "25 March",
    changes: [
      "Fix subdomain for customer site (VEP-546)",
      "Step id issue, edit asset req (VEP-544-545)",
      "UI: Micro site publishing: campaign / phase / step (VEP-548)",
    ],
  },
  {
    date: "22 March",
    changes: [
      "Add edit campaign modal (VEP-538)",
      "Add micro site context folder fields to phase and step (VEP-539)",
    ],
  },
  {
    date: "21 March",
    changes: [
      "Trim company fields(website,name) BE (VEP-529)",
      "Add publish asset (VEP-526)",
      "Common delete dialog component (VEP-532)",
      "Add micro site folder asset field (VEP-537)",
      "Add sFTP Users (VEP-524)",
    ],
  },
  {
    date: "19 March",
    changes: [
      "Edit no ref asset condition (VEP-517)",
      "Replace multiple spaces in campaign name (VEP-527)",
      "Company Details -> Customer Sites (VEP-423)",
    ],
  },
  {
    date: "18 March",
    changes: [
      "Added 'Local Variable' prompt argument option (VEP-521)",
      "Fix override edit item warning (VEP-497)",
      "The 'Move Left' and 'Move Right' commands have the arrows backwards (VEP-519)",
      "Fixed: Company filter gets set unexpectedly (VEP-522)",
    ],
  },
  {
    date: "15 March",
    changes: [
      "Fix edit asset issue, add additional from values processing (VEP-505)",
      "Clone step phase popup (VEP-498)",
      "Add modal clone campaign (VEP-509)",
      "Redevelop start campaign modal, add name field (VEP-511)",
      "Saving formatting text in the modal campaign deletion modal (VEP-512)",
      "Limit the input of the campaign name from 4 to 255 characters (VEP-510)",
    ],
  },
  {
    date: "12 March",
    changes: [
      "Fix the campaign loading bug (VEP-504)",
      "Fix insert link to the file error if global campaign (VEP-499)",
      "Actions/Settings Dropdown Redesign (VEP-500)",
      "Add reset override warning execute item (VEP-487)",
      "Add an override reset warning to the edit item modal (VEP-497)",
      "Fix version api base url (VEP-502)",
      "Campaign Dropdown Menu (VEP-501)",
      "Add file storage permissions (VEP-503)",
    ],
  },
  {
    date: "11 March",
    changes: [
      "Add override indicator (VEP-496)",
      "Include add phase/step to campaign actions (VEP-491)",
      "сampaignViewStorage icon needs to appear in phase, step and row menus (VEP-468)",
      "Add pagination after campaigns list (VEP-490)",
      "Fixed a problem with the creation step (hidden property is not set) (VEP-494)",
    ],
  },
  {
    date: "8 March",
    changes: [
      "Fix bug Payload Too Large when upload file in storage (VEP-372)",
      "Active menu tab on child pages (VEP-485)",
      "Add allow clear button (VEP-453)",
      "Options Menu alignment in table rows (VEP-486)",
      "Display an error when CM cannot parse the file when importing a campaign (VEP-484)",
      "Added error message if the file size exceeds the limit (VEP-488)",
      "Actions in the data grid row menu should have icons like other menus (VEP-467)",
    ],
  },
  {
    date: "7 March",
    changes: [
      "Fix rename campaign issue (VEP-480)",
      "Move actions to menu, move icons to a single file (VEP-481)",
      "Minor UI Redesign Fixes (VEP-476)",
      "Add row should also appear at the bottom of the data grid (VEP-465)",
      "Added font selector (VEP-482)",
      "CM Grid. Hide a button Edit for string variable (VEP-477)",
      "Fix bug in Safari (async copy) (VEP-483)",
      "Add/revise onEscape/onEnter in dialogs (VEP-454)",
    ],
  },
  {
    date: "5 March",
    changes: [
      "Optimize the processing of event messages (VEP-463)",
      "Changing error message for the position/title fields (VEP-417)",
      "Add search by companies name in the modal window Import Campaign (VEP-399)",
    ],
  },
  {
    date: "4 March",
    changes: [
      "Hide releases if not global admin (VEP-460)",
      "Show stop exec button when computation in progress (VEP-462)",
      "Show override as content if exists (VEP-469)",
      "Tabs Carousel Refactor(VEP-472)",
    ],
  },
  {
    date: "1 March",
    changes: [
      "Display path in events (VEP-446,459)",
      "Override Content Editor(VEP-434)",
      "Bug fixes (VEP-447,445,452,448,456)",
      "Insert file url on choose (VEP-453)",
      "Hide actions if empty list, clicking by company name opens details page (VEP-449, 450)",
    ],
  },
  {
    date: "29 February",
    changes: [
      "Table Redesign: Actions, Dropdown, Columns (Content, Result)(VEP-444)",
      "Add stop execute button, scroll to last message (VEP-369,370)",
      "Add company search to create campaign modal (VEP-399)",
      "Add import campaign hint (VEP-400)",
    ],
  },
  {
    date: "28 February",
    changes: [
      "campaign Table Redesign (partial) (VEP-433)",
      "upgrade prompt args component, add path to key name (VEP-440)",
      "trim asset fields(VEP-385)",
      "parse cm err message(to show cm error in ui) (VEP-442)",
    ],
  },
  {
    date: "27 February",
    changes: [
      "updated Phases/Steps UI(VEP-416)",
      "added 'asset' grid item (VEP-402, 413)",
      "added refresh file tree btn, fixed bugs related to storage (VEP-394, 340, 428)",
      "added processing of folder titles in the storage (Enhance the visual appeal of the naturalID) (VEP-441)",
    ],
  },
  {
    date: "23 February",
    changes: [
      "update Campaign Page UI: Header Component (VEP-414)",
      "update CM API Key when edit user with different roles (VEP-410)",
      "added global storage (VEP-375)",
      "added company storages (company/campaign/phase/step) (VEP-373)",
      "added the ability to add links to files in the grid item (VEP-421)",
    ],
  },
  {
    date: "21 February",
    changes: [
      "campaigns page UI updates (VEP-383)",
      "integrate posthog, add login/logout tracking events (VEP-403)",
      "add dynamic form fetching select options(draft version) (VEP-404)",
      "didn't display notification 'Required field!' for fields first name, last name, position (VEP-409)",
      "update file tree component for global storage (VEP-394-395)",
    ],
  },
  {
    date: "16 February",
    changes: [
      "navbar, footer, header UI updates (VEP-338)",
      "fixed bug-cannot edit company details (VEP-314)",
      "set default prompt filter value (env vars) (VEP-325)",
      "fixed bug-edit existing user (VEP-81)",
      "fixed bug-empty campaigns list(VEP-257)",
      "trim position field (user form) (VEP-297)",
      "fixed bug-execute cc item function with negative sequence (VEP-361)",
      "fixed bug-execute single cc item (VEP-322)",
      "fixed bug-displayed positions in incorrect column for users (VEP-332)",
      "added global storage(settings menu, page), not fully implemented (VEP-374)",
      "fixed issued related to adding a natural ID (VEP-382, 386, 396)",
      "moved api key from UI to node server(campaign, cc items) (VEP-272)",
    ],
  },
  {
    date: "6 February",
    changes: [
      "add campaign import/export (VEP-310)",
      "disable slashes in company & campaign names (VEP-325)",
      'disable cc item form submit on enter when in "key" field (VEP-330)',
      "Trim the space when creating/editing a key name in the campaign manager (VEP-323)",
    ],
  },
  {
    date: "2 February",
    changes: ["PRODUCTION RELEASE"],
  },
  {
    date: "30 January",
    changes: ['added "Execute campaign" with progress (VEP-246)'],
  },
  {
    date: "29 January",
    changes: [
      'added "Export to CSV" env vars action (VEP-288)',
      "added load files in dir by click, download file by click - campaign storage (VEP-256)",
      "hide file storage in settings and file chooser in asset form for Global campaigns(templates) (VEP-256)",
    ],
  },
  {
    date: "26 January",
    changes: [
      "added file preview in assets (VEP-256)",
      "added choosing campaign file in assets (VEP-256)",
      "added delete asset confirmation (VEP-292)",
    ],
  },
  {
    date: "25 January",
    changes: ["added simple file upload (VEP-256)"],
  },
  {
    date: "24 January",
    changes: [
      "added assets (url) (VEP-251)",
      "added templates (url) (VEP-294)",
    ],
  },
  {
    date: "19 January",
    changes: [
      "added a mechanism for logging out after the session expires (VEP-23)",
      "fixed bugs from previous sprints (VEP-88,82)",
      "BE - no visible changes (VEP-269)",
      "added company name and user email unique validation (BE) (VEP-27,73,81)",
      "added websocket reconnect on server error (VEP-213)",
    ],
  },
  {
    date: "18 January",
    changes: [
      "added company details (company users) (VEP-262)",
      "fixed bug with load global refactored by url (VEP-259)",
      "fixed bug with company url validation (VEP-258)",
      "fixed bug with refactored page for global admin (VEP-257)",
      "added confirmation of deletion by name (refactored/company) (VEP-247)",
    ],
  },
  {
    date: "8 January",
    changes: [
      "added hide/show phases/steps (need to implement security matrix) (VEP-228)",
      "refactored companies pages (VEP-224)",
      "refactored companies api BE (VEP-225)",
    ],
  },
  {
    date: "3 January",
    changes: [
      "added moving grid items feature (VEP-163)",
      "added clone phase/step feature (VEP-222)",
      "added hide/show phases/steps (VEP-228)",
    ],
  },
  {
    date: "29 December",
    changes: [
      "added move actions to phases, steps, and grid items (now there is a bug, see comments in jira task VEP-163)",
      "redirect to refactored list after delete (VEP-220)",
      "validate the company in the selector on the Campaigns page (VEP-223)",
      "added the alt+enter keyboard shortcut as a new line for text areas in forms (VEP-195)",
      "added auto focus in forms",
      "added preserving the text format for the env var value in the list",
    ],
  },
  {
    date: "26 December",
    changes: [
      "deprecate the current home page and the sprints menu item (VEP-184)",
    ],
  },
  {
    date: "25 December",
    changes: [
      "added phase and steps to url (VEP-158, 208)",
      "added error messages to notifications from the server (VEP-203)",
      "trim spaces before and after phase and step names (VEP-217)",
      "added preserving the key value when changing the type (VEP-215)",
    ],
  },
  {
    date: "22 December",
    changes: [
      "added text with formatting preserved in result/resultOverride fields",
      "fixed bug with editing env vars",
      "added execute cc item script (VEP-207)",
      "added switching the state of the grid item(VEP-166)",
    ],
  },
  {
    date: "20 December",
    changes: [
      'added option "All" to "CHOOSE TYPE" selector (VEP-199)',
      "added Campaigns pagination (VEP-186)",
      "added publish/unpublish refactored action (VEP-193, 205)",
      "clean up Campaign page behavior (VEP-192)",
      "added the ability to edit the key not only from the grid but also from the form (VEP-182,159)",
      "fixed a bug with cloning grid item (VEP-202,183)",
      "set the default type value for importing variables as prompt (VEP-201)",
      "added env var key editing (VEP-181)",
    ],
  },
  {
    date: "18 December",
    changes: [
      "added title column to the grid (VEP-151, 153)",
      "added grid item title editing (VEP-151, 153)",
      "refactored grid item key edititng, added validation",
    ],
  },
  {
    date: "15 December",
    changes: [
      "added global labels (VEP-151)",
      "added refactored labels (VEP-153)",
    ],
  },
  {
    date: "14 December",
    changes: [
      "added grid item clone feature (VEP-183)",
      "added editing key of grid item (warning!!! so far, no validation - be careful when editing) (VEP-182)",
    ],
  },
  {
    date: "13 December",
    changes: [
      "added the title on page Campaigns (VEP-143,144)",
      'removed action "delete" env var (single)',
      "fixed env var validation (but for the moment there is a bug!!! see VEP-181)",
      "added refactored loading from url (partially solved, need to add phase and step to url) (VEP-158)",
    ],
  },
  {
    date: "12 December",
    changes: [
      "added validation of an existing key in the step (VEP-171)",
      "added validation of an existing key (env vars)",
      "added bulk delete environment variables (VEP-174)",
      "added filter of env vars (VEP-173)",
    ],
  },
  {
    date: "11 December",
    changes: [
      "added an option Recalculate all into execution confirm of phases and steps (VEP-160,161)",
      "refinement of the argument selection component of the prompt (VEP-168,169)",
    ],
  },
  {
    date: "8 December",
    changes: [
      "refactored env var key validation (allowed hyphens) (VEP-167)",
      "added hints of valid values to Key fields (VEP-148)",
      "added feature: automatically set previous Key value in Env and CC items(local storage approach) (VEP-165)",
      "added features: pressing enter to save a line, esc to cancel a line (VEP-164)",
      "fixed bug with updating content and setting fields (VEP-157)",
    ],
  },
  {
    date: "7 December",
    changes: [
      "upgraded the app (react, router, axios, ts)",
      "added prompt template to select options",
      "added odd/even row colors & hover highlighting",
    ],
  },
  {
    date: "5 December",
    changes: [
      "added a popup with computation events",
      'added edit computation item option (icon in "Key" field)',
      "fixed bugs with web sockets, added error handling(highlighting)",
      "added result override popup",
      "added clone refactored feature",
      "added export as CSV (phase/step)",
    ],
  },
  {
    date: "1 December",
    changes: [
      "added the ability to add a phase before and after",
      "added the ability to add a step before and after",
      "added icons to all menus",
      "added copy option to result field",
      "added automatic filling with default values from the selected prompt",
      "added updating of table cells via websockets when executing the script",
      "added highlighting of updated cells (beta)",
    ],
  },
  {
    date: "30 November",
    changes: [
      "added a mechanism for resetting prompt arguments of variables if their number has changed",
      "added status icons for grid variables, added cell highlighting in red if an error occurs",
      "added simple modal confirmations for execution and deletion",
      "added releases component",
    ],
  },
];

const ReleasesPopup: React.FC<TReleasesPopupProps> = (props) => {
  const { setIsModalOpened, isModalOpened } = props;
  const [releaseData, setReleaseData] = useState<TReleaseData | null>(null);
  const [isFetching, toggleIsFetching] = useState(false);

  useEffect(() => {
    const getReleaseData = async () => {
      try {
        toggleIsFetching(true);

        const BASE_URL_CM = process.env.REACT_APP_CM_API_URL;
        const res = await axios.get<TReleaseData>(
          `${BASE_URL_CM}/api/public/version`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setReleaseData(res.data);
      } catch (e) {
        console.error("Can't get release data", e);
      } finally {
        toggleIsFetching(false);
      }
    };
    getReleaseData();
  }, []);

  const handleCancel = () => {
    setIsModalOpened(false);
  };

  return (
    <Modal
      title="Releases Info"
      open={isModalOpened}
      onCancel={handleCancel}
      footer={null}
      centered
      width={"80vW"}
    >
      <Flex style={{ height: "80vh" }} vertical gap="middle">
        {isFetching && (
          <div>
            <Spin />
          </div>
        )}
        {releaseData && (
          <Flex vertical>
            {Object.entries(releaseData).map((info) => {
              return (
                <div key={info[0]}>
                  <Typography.Text
                    strong
                    style={{ textTransform: "uppercase", width: "100px" }}
                  >
                    {info[0]}:
                  </Typography.Text>
                  <Typography.Text code>{info[1]}</Typography.Text>
                </div>
              );
            })}
            <Typography.Text strong style={{ marginTop: "24px" }}>
              CHANGE LOG
            </Typography.Text>
          </Flex>
        )}
        <Flex
          vertical
          gap="middle"
          style={{ height: "100%", overflowY: "scroll" }}
        >
          {messagedData.map((data) => {
            return (
              <Flex gap="middle" key={data.date}>
                <div style={{ width: "130px" }}>
                  <Typography.Text strong>{data.date}</Typography.Text>
                </div>
                <Alert
                  style={{ width: "100%" }}
                  message={
                    <Flex vertical>
                      <Typography.Text mark>Changes:</Typography.Text>
                      {data.changes.map((change) => {
                        return (
                          <div key={change}>
                            <CheckOutlined style={{ marginRight: "8px" }} />
                            <Typography.Text italic>{change}</Typography.Text>
                          </div>
                        );
                      })}
                    </Flex>
                  }
                  type="info"
                />
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ReleasesPopup;
