import { Empty, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUiTemplatesOptions } from "src/hooks/useTemplatesOptions";
import CampaignClasses from "src/pages/campaign/components/StepData/CampaignClasses";
import { selectCurrentPhase } from "src/store/slices/phasesSlice";
import {
  selectCurrentStep,
  updateCurrentStepTemplateThunk,
} from "src/store/slices/stepsSlice";
import { DocumentTemplateState } from "src/types/docTemplates";
import { getStepTemplatePreviewApi } from "../../api/steps.api";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { selectMessageApi } from "../../store/slices/appSlice";
import { TCcVariable } from "../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../store/store";
import handleRequestError from "../../utils/handleRequestError";

import { FileMimeType } from "src/types";
import {
  CampaignStateIndicator,
  DocViewActions,
  DocViewSelector,
  EditableIframe,
} from "./components";

export type TCampaignDocViewProps = {
  companyId: number | undefined;
  ccVariables: Array<TCcVariable>;
};

const CampaignDocView: FC<TCampaignDocViewProps> = ({
  companyId,
  ccVariables,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const currentPhase = useSelector(selectCurrentPhase);
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const [blob, setBlob] = useState<Blob>();
  const [content, setContent] = useState<string>("");
  const { isGlobalAdmin } = useCurrentUser();
  const {
    options,
    load,
    selectedOption,
    isLoading: IsTemplatesLoading,
  } = useUiTemplatesOptions({ displayTitle: true });
  const currentTemplateId = currentStep?.documentTemplateId || undefined;
  const [scrollTop, setScrollTop] = useState(0);
  const [isPdf, setIsPdf] = useState(false);
  const [pdfSrc, setPdfSrc] = useState("");

  const [isVeTemplate, setIsVeTemplate] = useState(true);

  const loadTemplateOptions = async () => {
    if (!currentStep) {
      console.warn("Unable to get [currentStep]");
      return;
    }

    await load({
      states: [DocumentTemplateState.PUBLISHED],
      classes: currentStep.classes || [],
      stepId: currentStep.id,
      companyId,
    });
  };

  useEffect(() => {
    loadTemplateOptions();
  }, [currentStep?.id, currentStep?.classes]);

  const setSelectedTemplate = (templateId: number | null) => {
    dispatch(updateCurrentStepTemplateThunk({ templateId }));
  };

  const handlePreview = async (templateId?: number | null) => {
    if (!templateId) {
      setBlob(undefined);
      setContent("");
      return;
    }

    try {
      setIsFetching(true);

      if (!currentPhase?.id || !currentStep?.id) {
        messageApi.error("Missing required data!");
        return;
      }

      const { data } = await getStepTemplatePreviewApi({
        phaseId: currentPhase.id,
        stepId: currentStep.id,
        template: templateId,
        companyId: companyId,
      });

      const isPdf = selectedOption?.info.mimeType === FileMimeType.PDF;

      const sourceBlob = new Blob([data], {
        type: isPdf ? "application/pdf" : "text/html",
      });

      const pdfSrc = URL.createObjectURL(sourceBlob);
      setIsPdf(!!isPdf);
      setPdfSrc(pdfSrc);
      setBlob(sourceBlob);

      if (sourceBlob) {
        const blobContent = await sourceBlob.text();
        setContent(blobContent);
      } else {
        setContent("");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onTabPreview = async () => {
    try {
      if (!blob) {
        messageApi.error("No blob data!");
        return;
      }

      setIsFetching(true);

      const blobContent = URL.createObjectURL(blob);

      window.open(blobContent, "_blank");
      URL.revokeObjectURL(blobContent);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const handleTemplateLoaded = (doc: Document) => {
    const veEl = doc.querySelector("[ve-content]");
    setIsVeTemplate(!!veEl);
  };

  useEffect(() => {
    handlePreview(selectedOption ? currentTemplateId : undefined);
  }, [selectedOption, currentStep]);

  useEffect(() => {
    setScrollTop(0);
  }, [currentStep?.id]);

  const handleReload = () => {
    handlePreview(currentTemplateId || undefined);
  };

  const handleKeyAdded = () => {
    loadTemplateOptions();
  };

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <CampaignClasses />
          <div className="flex items-center gap-2 ev-ant">
            <CampaignStateIndicator ccVariables={ccVariables} />
            <DocViewSelector
              options={options}
              isTemplatesLoading={IsTemplatesLoading}
              currentTemplateId={currentTemplateId}
              setSelectedTemplate={setSelectedTemplate}
              selectedOption={selectedOption}
              isGlobalAdmin={isGlobalAdmin}
              isVeTemplate={isVeTemplate}
            />
          </div>
          <DocViewActions
            disabled={!currentTemplateId}
            isBlob={!!blob}
            onReloadClick={handleReload}
            onTabPreviewClick={onTabPreview}
          />
        </div>

        {isFetching ? (
          <Spin className="flex justify-center items-center w-full mt-4 border border-slate-300 rounded-md h-[calc(200px)]" />
        ) : (
          <>
            {content ? (
              <EditableIframe
                ccVariables={ccVariables}
                initialContent={content}
                onUpdated={handleReload}
                companyId={companyId}
                onTemplateLoaded={handleTemplateLoaded}
                scrollTop={scrollTop}
                setScrollTop={setScrollTop}
                isPdf={isPdf}
                pdfSrc={pdfSrc}
                onKeyAdded={handleKeyAdded}
              />
            ) : (
              <Empty
                imageStyle={{ height: "30vh" }}
                className="rounded-md border w-full mx-auto py-6 mt-4"
                description={
                  <div>
                    <span className="font-sans font-medium text-[#0F172A]">
                      Select template to preview
                    </span>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CampaignDocView;
