import { Button, Checkbox } from "antd";
import clsx from "clsx";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saveResultOvrApi } from "../../api/cc-variables.api";
import { ChangeNodeProps } from "../../pages/graph/hooks/useGetNodes.hook";
import { selectMessageApi } from "../../store/slices/appSlice";
import { TCcVariable } from "../../store/slices/ccVariablesSlice";
import handleRequestError from "../../utils/handleRequestError";
import { Badge } from "../common/ui/badge";
import { Textarea } from "../common/ui/textarea";

type Props = {
  ccItem: TCcVariable;
  changeNodeProps?: ChangeNodeProps;
  setItem: Dispatch<SetStateAction<TCcVariable | null>>;
  onOverrideSave?: () => void;
};

const PaneResultOverride: FC<Props> = ({
  ccItem,
  changeNodeProps,
  setItem,
  onOverrideSave,
}) => {
  const { id, result, resultOvr } = ccItem;
  const messageApi = useSelector(selectMessageApi);
  const [recomputeDownstream, setRecomputeDownstream] = useState(false);
  const [overrideValue, setOverrideValue] = useState<string>(
    resultOvr || result || ""
  );
  const [isFetching, setIsFetching] = useState(false);
  const { companyId } = useParams();
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;

  const isNoChanges = overrideValue === result;

  useEffect(() => {
    setOverrideValue(ccItem.resultOvr || ccItem.result || "");
  }, [ccItem]);

  const handleSaveOverride = async () => {
    try {
      setIsFetching(true);

      await saveResultOvrApi(
        id.campaignId,
        id.key,
        id.stepId,
        overrideValue,
        companyIdValidated,
        recomputeDownstream
      );

      setItem((item) => {
        return item ? { ...item, resultOvr: overrideValue } : null;
      });

      changeNodeProps?.({
        nodeId: `${id.key}_${id.stepId}`,
        props: { touched: true },
      });
      onOverrideSave?.();
      messageApi.success("Successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="flex justify-between px-[2px]">
      <div className="w-[49%] relative">
        <Badge
          className="absolute top-0 left-[12px] bg-green-300 text-green-950"
          variant={"table"}
        >
          Override Version
        </Badge>

        <Textarea
          className={clsx("w-full mt-[10px] h-full p-[14px]", {
            "text-black/40": isNoChanges,
          })}
          onChange={(e) => setOverrideValue(e.target.value)}
          value={overrideValue}
        />
        <div className="flex flex-col gap-[4px] mt-[8px]">
          <div>
            <Checkbox
              onChange={(e) => setRecomputeDownstream(e.target.checked)}
            >
              <span className="text-sm">
                Recompute downstream key dependencies
              </span>
            </Checkbox>
          </div>
          <div>
            <Button
              size="small"
              className="mr-[16px]"
              onClick={() => setOverrideValue(result || "")}
              disabled={isNoChanges}
            >
              Restore Original
            </Button>
            <Button
              size="small"
              type="primary"
              loading={isFetching}
              onClick={handleSaveOverride}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="w-[49%] relative">
        <Badge
          className="absolute top-0 left-[12px] bg-blue-300 text-blue-950"
          variant={"table"}
        >
          Result Output
        </Badge>
        <Textarea
          className="w-full mt-[10px] h-full p-[14px]"
          defaultValue={result || ""}
          disabled
        />
      </div>
    </div>
  );
};

export default PaneResultOverride;
