import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "../constants";
import { GraphEdge, GraphMetaInfo, GraphNode, TAsset } from "../globalTypes";
import {
  TCcDynamicFormItemsAsset,
  TCcDynamicFormItemsFunction,
  TCcDynamicFormItemsPrompt,
  TCcDynamicFormItemsString,
  TCcDynamicFormItemsWebScrapper,
  TCcVariable,
  TCcVariableType,
  TCcVarsFormValues,
  TComputationState,
  TPromptCcVarServicePovider,
} from "../store/slices/ccVariablesSlice";
import axiosInstance from "../utils/axios/axiosInstance";

//TODO: move variable to project constants
const CM_API_PREFIX = "/api/secure/campaignManager";

export const getCcVarsApi = (
  campaignId: number,
  stepId: number,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TCcVariable>>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/${campaignId}/cc/step/${stepId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export type TGetCcVarTypesResponse = {
  function: { items: TCcDynamicFormItemsFunction };
  prompt: { items: TCcDynamicFormItemsPrompt };
  string: { items: TCcDynamicFormItemsString };
  asset: { items: TCcDynamicFormItemsAsset };
  web_scrapper: { items: TCcDynamicFormItemsWebScrapper };
};

export const getCcVarTypesApi = (
  companyId: number | undefined
): Promise<AxiosResponse<TGetCcVarTypesResponse>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/global/cc-types/`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export const createCcVarApi = (
  campaignId: number,
  values: TCcVarsFormValues,
  stepId: number,
  seq: number,
  action: "create" | "update",
  companyId: number | undefined
): Promise<AxiosResponse<TCcVariable>> => {
  const { type, key, result, ...options } = values;
  const method = action === "create" ? "post" : "put";

  return axiosInstance[method](
    `${CM_API_PREFIX}/${campaignId}/cc/${type}`,
    {
      id: { key, stepId },
      seq,
      result,
      type,
      options,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const editCcVarKeyApi = ({
  campaignId,
  currentKey,
  newKey,
  companyId,
  stepId,
}: {
  campaignId: number;
  stepId: number;
  currentKey: string;
  newKey: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/change-key/${stepId}/${currentKey}/${newKey}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const deleteCcVarApi = ({
  campaignId,
  stepId,
  companyId,
  key,
}: {
  campaignId: number;
  stepId: number;
  key: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/${stepId}/${key}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const executeCcVarApi = (
  campaignId: number,
  key: string,
  stepId: number,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `${CM_API_PREFIX}/${campaignId}/computation/${stepId}/cc-item/${key}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const validateCcVarApi = (
  campaignId: number
): Promise<AxiosResponse<boolean>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/execute/validate/`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const saveResultOvrApi = (
  campaignId: number,
  key: string,
  stepId: number,
  value: string,
  companyId: number | undefined,
  recomputeDownStream?: boolean
): Promise<AxiosResponse<TCcVariable>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${campaignId}/cc/${stepId}/${key}/content`,
    value,
    {
      headers: {
        "Content-Type": "text/plain",
      },
      params: {
        companyId,
        recomputeDownStream,
      },
    }
  );
};

export type TPromptTemplate = {
  key: string;
  label: string;
  template: string;
  options: {
    serviceProvider: TPromptCcVarServicePovider;
    modelName: string;
  };
};

export const getPromptTemplatesApi = (
  campaignId: number,
  type: TCcVariableType,
  searchPart: string,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TPromptTemplate>>> => {
  return axiosInstance.get(
    `${CM_API_PREFIX}/${campaignId}/env/${type}/lookup`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        searchPart,
        companyId,
      },
    }
  );
};

export interface PromptTemplateByKey {
  id: {
    campaignId: number;
    key: string;
  };
  type: string;
  options: {
    aiModelId: any;
  };
  value: string;
}

export const getPromptTemplateByKey = (
  campaignId: number,
  key: string,
  companyId: number | undefined
): Promise<AxiosResponse<PromptTemplateByKey>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/env/${key}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export interface TemplateByPath {
  id: {
    campaignId: number;
    stepId: number;
    key: string;
  };
  seq: number;
  type: string;
  result: string;
  state: string;
  resultOvr: any;
  mimeType: string;
  executable: boolean;
  supportOverride: boolean;
}

export const getCcTemplateByPath = (
  campaignId: number,
  path: string,
  companyId: number | undefined
): Promise<AxiosResponse<TemplateByPath>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/findPath/`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
        path,
      },
    }
  );
};

export type TPromptVar = {
  path: Array<string>;
  key: string;
  label: string;
};
export const getPromptVarsApi = (
  campaignId: number,
  stepId: number,
  seq: number,
  searchPart: string,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TPromptVar>>> => {
  return axiosInstance.get(
    `${CM_API_PREFIX}/${campaignId}/cc/${stepId}/${seq}/lookup`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        searchPart,
        companyId,
      },
    }
  );
};

export const getPromptVarTemplateApi = (
  campaignId: number,
  stepId: number,
  seq: number,
  searchPart: string,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TPromptVar & { content: string }>>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/content/${stepId}/${seq}/lookup`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        searchPart,
        companyId,
      },
    }
  );
};

export const executeFunctionCcVarApi = ({
  campaignId,
  stepId,
  companyId,
  seq,
  scriptContent,
}: {
  campaignId: number;
  stepId: number;
  seq: number;
  scriptContent: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `${CM_API_PREFIX}/${campaignId}/computation/cc-item/${stepId}/${seq}/script`,
    scriptContent,
    {
      headers: {
        "Content-Type": "text/plain",
      },
      params: {
        companyId,
      },
    }
  );
};

export const updateCcVarStateApi = (
  campaignId: number,
  key: string,
  stepId: number,
  state: TComputationState,
  companyId: number | undefined
): Promise<AxiosResponse<undefined>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${campaignId}/cc/${stepId}/${key}/state/${state}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const swapCcVarSequenceApi = (
  campaignId: number,
  stepId: number,
  cc1Seq: number,
  cc2Seq: number,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TCcVariable>>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${campaignId}/cc/${stepId}/${cc1Seq}/${cc2Seq}/swap`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const getProviderModelsApi = ({
  provider,
  companyId,
}: {
  provider: string;
  companyId?: number | undefined;
}): Promise<AxiosResponse<Array<string>>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/llm-utils/${provider}/models`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const getProviderOptionsApi = ({
  url,
  companyId,
}: {
  url: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<Array<string>>> => {
  return axiosInstance.get(`${PROXY_PREFIX}${url}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export const getDependenciesApi = ({
  campaignId,
  stepId,
  key,
  companyId,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
  companyId: number | undefined;
}): Promise<
  AxiosResponse<{
    nodes: GraphNode[];
    edges: GraphEdge[];
    metaInfo: GraphMetaInfo;
  }>
> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/${stepId}/${key}/dependencies`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const getCCItemApi = ({
  campaignId,
  stepId,
  key,
  companyId,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<TCcVariable>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/${stepId}/${key}/find`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const getCcVarApi = ({
  campaignId,
  stepId,
  key,
  companyId,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<TCcVariable>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/${stepId}/${key}/get`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const redeployCCItemApi = ({
  campaignId,
  stepId,
  key,
  companyId,
}: {
  campaignId: number;
  stepId: number;
  key: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/cc/microsite-redeploy/${stepId}/${key}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};
