import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { CampaignViewTypes } from "src/types";
import {
  cloneStepApi,
  createStepApi,
  deleteStepApi, redeployStepToMicroSiteApi,
  swapStepSequenceApi,
  updateStepApi,
} from "../../api/steps.api";
import { TCloneEntityFormValues } from "../../components/common/modals/CloneEntityModal/CloneEntityModal";
import { sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCurrentCampaignView } from "./campaignsSlice";
import { setCcVariables } from "./ccVariablesSlice";

export type TStep = {
  phaseId: number;
  seq: number;
  id: number;
  name: string;
  hidden: boolean;
  naturalId: string;
  microSiteContextFolder: null | string;
  documentTemplateId: number | null;
  hiddenDocView: boolean;
  classes: null | string[];
  republish: boolean;
};

export type TStepPathMap = {
  [key: number]: string;
};

const initialState = {
  current: null as TStep | null,
  list: [] as Array<TStep>,
  stepPathMap: {} as TStepPathMap,
};

type InitialStateType = typeof initialState;

const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    cleanUpSteps: (state: InitialStateType) => {
      state.current = null;
      state.list = [];
    },
    addStep: (state: InitialStateType, action: PayloadAction<TStep>) => {
      state.list.push(action.payload);
    },
    setStepPathMap: (
      state: InitialStateType,
      action: PayloadAction<TStepPathMap>
    ) => {
      state.stepPathMap = action.payload;
    },
    setSteps: (
      state: InitialStateType,
      action: PayloadAction<Array<TStep>>
    ) => {
      state.list = action.payload;
    },
    updateStep: (state: InitialStateType, action: PayloadAction<TStep>) => {
      const editedStep = action.payload;
      const index = state.list.findIndex((step) => step.id === editedStep.id);

      state.list[index] = editedStep;
    },
    setCurrentStep: (
      state: InitialStateType,
      action: PayloadAction<TStep | null>
    ) => {
      state.current = action.payload;
    },
    deleteStep: (state: InitialStateType, action: PayloadAction<number>) => {
      state.list = state.list.filter((step) => step.id !== action.payload);
    },
  },
});

export const {
  cleanUpSteps,
  addStep,
  setSteps,
  updateStep,
  setCurrentStep,
  deleteStep,
  setStepPathMap,
} = stepsSlice.actions;

export default stepsSlice.reducer;

export const selectStepsList = (state: RootState) => state.steps.list;
export const selectDocVisibleSteps = createSelector(
  selectStepsList,
  (list: RootState["steps"]["list"]) => {
    return list.filter((step) => !step.hiddenDocView);
  }
);
export const selectCurrentStep = (state: RootState) => state.steps.current;

/* eslint-disable*/
export const getSteps = (): Array<TStep> => useSelector(selectStepsList);
export const getCurrentStep = (): TStep | null =>
  useSelector(selectCurrentStep);

type TSaveStepThunkProps = {
  phaseId: number;
  stepName: string;
  sequence: number;
  hidden: boolean;
  microSiteContextFolder: null | string;
  classes: string[];
};

export const saveStepThunk = createAsyncThunk<
  { phaseId: number; stepId: number },
  TSaveStepThunkProps,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/saveStep",
  async (
    { stepName, sequence, phaseId, hidden, microSiteContextFolder, classes },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { steps, companies } = getState();
      const { data: step } = await createStepApi({
        phaseId,
        name: stepName,
        seq: sequence,
        hidden: hidden || false,
        microSiteContextFolder: microSiteContextFolder || "",
        classes,
        companyId: companies.current?.id,
        hiddenDocView: false,
      });
      // const step = data.step;
      const allSteps = [...steps.list];

      allSteps.push(step);

      const sortedSteps = sortBySequence(allSteps);

      dispatch(setSteps(sortedSteps));
      dispatch(setCurrentStep(step));
      dispatch(setCcVariables([]));

      return { phaseId: step.phaseId, stepId: step.id };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to save the step:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const updateStepThunk = createAsyncThunk<
  string,
  { step: TStep; republish: boolean },
  { state: RootState; rejectValue: TCustomError }
>("steps/updateStep", async ({ step, republish }, { getState, rejectWithValue, dispatch }) => {
  try {
    dispatch(toggleIsFetching(true));

    const { companies } = getState();

    const { data: updatedStepData } = await updateStepApi({
      step,
      companyId: companies.current?.id,
    });

    if(republish){
      await redeployStepToMicroSiteApi(step.phaseId, step.id, companies.current?.id);
    }

    dispatch(updateStep(updatedStepData));
    dispatch(setCurrentStep(updatedStepData));

    return "success";
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to update the step:`, e);

    return rejectWithValue(customError);
  } finally {
    dispatch(toggleIsFetching(false));
  }
});

export const updateCurrentStepTemplateThunk = createAsyncThunk<
  string,
  { templateId: number | null },
  { state: RootState; rejectValue: TCustomError }
>(
  "steps/updateStepTemplate",
  async ({ templateId }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));
      const { steps, companies } = getState();

      const step = steps.current;

      if (step) {
        const stepWithUpdatedTemplate: TStep = {
          ...step,
          documentTemplateId: templateId,
        };

        await updateStepApi({
          step: stepWithUpdatedTemplate,
          companyId: companies.current?.id,
        });

        dispatch(updateStep(stepWithUpdatedTemplate));
        dispatch(setCurrentStep(stepWithUpdatedTemplate));
      }

      return "success";
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to update the step template:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const updateHiddenDocViewThunk = createAsyncThunk<
  string,
  { hiddenDocView: boolean },
  { state: RootState; rejectValue: TCustomError }
>(
  "steps/updateHiddenDocView",
  async ({ hiddenDocView }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { steps, companies, campaigns } = getState();

      const step = steps.current;

      // @ts-ignore
      const stepWithUpdatedHiddenDocView: TStep = {
        ...step,
        hiddenDocView,
      };

      await updateStepApi({
        step: stepWithUpdatedHiddenDocView,
        companyId: companies.current?.id,
      });

      if (hiddenDocView && campaigns.currentView === CampaignViewTypes.DOC) {
        dispatch(setCurrentCampaignView(CampaignViewTypes.GRID));
      }

      dispatch(updateStep(stepWithUpdatedHiddenDocView));
      dispatch(setCurrentStep(stepWithUpdatedHiddenDocView));

      return "success";
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to update the step hiddenDocView:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const updateStepSeqThunk = createAsyncThunk<
  string,
  TStep,
  { state: RootState; rejectValue: TCustomError }
>("steps/updateStep", async (step, { getState, rejectWithValue, dispatch }) => {
  try {
    dispatch(toggleIsFetching(true));

    const { companies } = getState();

    await updateStepApi({ step, companyId: companies.current?.id });

    dispatch(updateStep(step));
    dispatch(setCurrentStep(step));

    return "success";
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to update the step:`, e);

    return rejectWithValue(customError);
  } finally {
    dispatch(toggleIsFetching(false));
  }
});

export const deleteStepThunk = createAsyncThunk<
  { stepId: number | undefined },
  {
    stepId: number;
    phaseId: number;
    stepIndex: number;
    removeFiles: boolean;
    companyId: number | undefined;
  },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/deleteStep",
  async (
    { stepId, phaseId, companyId, removeFiles, stepIndex },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      await deleteStepApi({
        phaseId,
        stepId,
        removeFiles,
        companyId,
      });

      const { steps } = getState();
      const allSteps = steps.list;
      //if it was not the only step - set current step as null and steps empty list
      let updatedSteps: Array<TStep> = [];
      let step: TStep | null = null;

      //if it was not the only step
      if (allSteps.length !== 1) {
        //delete the step from list
        updatedSteps = allSteps.filter((step) => step.id !== stepId);

        //if it was first step - set next as current
        if (stepIndex === 0) {
          step = updatedSteps[0];
        } else {
          //if it was not first step - set previous as current
          step = updatedSteps[stepIndex - 1];
        }
      }

      dispatch(setCurrentStep(step));
      dispatch(setSteps(updatedSteps));

      return {
        stepId: step?.id,
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to delete the step:`, e);

      return rejectWithValue(customError);
    }
  }
);

export const swapStepSequenceThunk = createAsyncThunk<
  undefined,
  { currentStep: TStep; seqToSwap: number },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/swapStepSequenceThunk",
  async (
    { currentStep, seqToSwap },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { phases, companies } = getState();
      const { data: updatedStepsList } = await swapStepSequenceApi(
        phases.current!.id,
        currentStep.seq,
        seqToSwap,
        companies.current?.id
      );
      const steps = updatedStepsList.steps;
      const sortedSteps = sortBySequence<TStep>(steps);
      const updatedCurrentStep: TStep = {
        ...currentStep,
        seq: seqToSwap,
      };

      dispatch(setCurrentStep(updatedCurrentStep));
      dispatch(setSteps(sortedSteps));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to move the step:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const cloneStepThunk = createAsyncThunk<
  undefined,
  { step: TStep; params: TCloneEntityFormValues },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/cloneStepThunk",
  async ({ step, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { phases, steps, companies } = getState();
      const { data } = await cloneStepApi({
        phaseId: phases.current!.id,
        stepId: step.id,
        companyId: companies.current?.id,
        ...params,
      });

      const clonedStep = data.step;
      const index = steps.list.findIndex(
        (stepFromList) => stepFromList.id === step.id
      );
      let updatedStepsList: Array<TStep> = [];

      if (index !== -1) {
        if (steps.list.length - 1 === index) {
          updatedStepsList = [...steps.list, { ...clonedStep }];
        } else {
          updatedStepsList = [
            ...steps.list.slice(0, index + 1),
            { ...clonedStep },
            ...steps.list.slice(index + 1),
          ];
        }
      }

      dispatch(setSteps(sortBySequence(updatedStepsList)));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to clone the step:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);
