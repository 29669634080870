import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Edge, Node } from "reactflow";
import { getDependenciesApi } from "../../../api/cc-variables.api";
import { TStepPhaseMetaInfo } from "../../../globalTypes";
import useConfirm from "../../../hooks/useConfirm";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  TCcVariable,
  TComputationState,
  loadDynamicItemsThunk,
} from "../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import { INIT_NODE_DIMENSIONS, INIT_NODE_POSITION } from "../constants";
import getLayoutedElements from "../utils/getLayoutedElements";
import getStepPhaseMetaInfo from "../utils/getStepPhaseMetaInfo";
import getTargetWithInputCCItem from "../utils/getTargetWithInputCCItem";
import getUpdatedEdgeStyles from "../utils/getUpdatedEdgeStyles";
import getUpdatedNodeStyles from "../utils/getUpdatedNodeStyles";
import removeLastUnderscorePart from "../utils/removeLastUnderscorePart";
import useNodeMenuActions from "./useNodeMenuActions.hook";

export enum NodeColor {
  TARGET = "#4F46E5",
  INPUT = "#3cbd77",
  DEFAULT = "#dedede",
}

export type ChangeNodeProps = ({ nodeId, props }: {
  nodeId: string;
  props: TUpdateNodeProps;
}) => void;

export type TUpdateNodeProps = { touched?: boolean; state?: TComputationState };

const useGetNodesHook = () => {
  const { companyId, campaignId, stepId, key } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [targetNode, setTargetNode] = useState<TCcVariable | null>(null);
  const [inputNode, setInputNode] = useState<TCcVariable | null>(null);
  const [isTargetLoading, setIsTargetLoading] = useState(false);
  const [isInputLoading, setIsInputLoading] = useState(false);
  const [stepPhaseMetaInfo, setStepPhaseMetaInfo] =
    useState<TStepPhaseMetaInfo | null>(null);
  const { setTarget, setTargetWithInput, setInput, goToKeyDefinition, reRun } =
    useNodeMenuActions({
      setTargetNode,
      setInputNode,
      setNodes,
      inputNode,
      targetNode,
      setEdges,
      setIsTargetLoading,
      setIsInputLoading,
    });
  const dispatch: AppDispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();
  const initialNodeId = `${key}_${stepId}`;
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;

  useEffect(() => {
    if (campaignId && stepId && key) {
      getNodesAndEdges(companyIdValidated, campaignId, stepId, key);
    }
  }, [companyId, campaignId, stepId, key]);

  const getNodesAndEdges = async (
    companyId: number | undefined,
    campaignId: string,
    stepId: string,
    key: string
  ) => {
    try {
      setIsTargetLoading(true);
      setIsInputLoading(true);

      await dispatch(loadDynamicItemsThunk({ companyId })).unwrap();

      const { data } = await getDependenciesApi({
        campaignId,
        stepId,
        key,
        companyId,
      });

      const { targetCCItem, inputCCItem } = await getTargetWithInputCCItem({
        campaignId,
        stepId,
        key,
        companyId,
      });

      const { edges, nodes, metaInfo } = data;
      const stepPhaseMetaInfoData = getStepPhaseMetaInfo(metaInfo);

      const processedEdges: Edge[] = edges.map(
        ({ id, targetNodeId: target, sourceNodeId }) => ({
          id: id,
          source: sourceNodeId,
          target,
          type: "simplebezier",
          focusable: false,
          ...getUpdatedEdgeStyles({
            nodeId: target,
            targetNodeId: `${targetCCItem.id.key}_${targetCCItem.id.stepId}`,
            inputNodeId: inputCCItem
              ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
              : "",
          }),
        })
      );

      const processedNodes: Node<any>[] = nodes.map(({ id, data }) => ({
        id,
        data: {
          label: removeLastUnderscorePart(id),
          touched: false,
          state: data.state,
          setTargetWithInput: () => setTargetWithInput(data.id.key),
          setInput: () => setInput(data.id.key),
          setTarget: () => setTarget(data.id.key),
          goToKeyDefinition: () => {
            const stepId = data.id.stepId;
            const { phaseId } = stepPhaseMetaInfoData[stepId];

            if (phaseId) {
              goToKeyDefinition({
                phaseId,
                stepId: data.id.stepId,
                ccItemKey: data.id.key,
              });
            }
          },
          reRun: () => {
            confirm({
              action: () =>
                reRun({
                  campaignId: data.id.campaignId,
                  stepId: data.id.stepId,
                  ccItemKey: data.id.key,
                }),
              title: "Execute Item",
            });
          },
        },
        position: INIT_NODE_POSITION,
        parentId: data.id.stepId.toString(),
        draggable: false,
        selectable: false,
        zIndex: 2,
        style: getUpdatedNodeStyles({
          nodeId: id,
          targetNodeId: `${targetCCItem.id.key}_${targetCCItem.id.stepId}`,
          inputNodeId: inputCCItem
            ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
            : "",
        }),
      }));

      const groupNodes: Node<any>[] = metaInfo.steps.map(({ name, id }) => {
        let label = name;
        const { phaseName } = stepPhaseMetaInfoData[id];

        if (phaseName) {
          label = `${phaseName} / ${name}`;
        } else {
          console.warn("Couldn't find phase");
        }

        return {
          id: id.toString(),
          type: "group",
          data: { label },
          position: INIT_NODE_POSITION,
          style: INIT_NODE_DIMENSIONS,
          draggable: false,
          selectable: false,
          zIndex: 2,
        };
      });

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements([...groupNodes, ...processedNodes], processedEdges);

      setEdges(layoutedEdges);
      setNodes(layoutedNodes);
      setTargetNode(targetCCItem);
      setInputNode(inputCCItem);
      setStepPhaseMetaInfo(getStepPhaseMetaInfo(metaInfo));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
      setIsTargetLoading(false);
      setIsInputLoading(false);
    }
  };

  const onGoBack = () => {
    location.key !== "default"
      ? navigate(-1)
      : navigate(
          `/admin/campaigns/company/${companyId}/campaign/${campaignId}/`
        );
  };

  const changeNodeProps = useCallback(
    ({ nodeId, props }: { nodeId: string; props: TUpdateNodeProps }) => {
      setNodes((prevState) => {
        return prevState.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: { ...node.data, ...props },
            };
          } else {
            return node;
          }
        });
      });
    },
    []
  );

  return {
    edges,
    nodes,
    isInitialized,
    initialNodeId,
    isTargetLoading,
    isInputLoading,
    targetNode: useMemo(() => targetNode, [targetNode]),
    inputNode: useMemo(() => inputNode, [inputNode]),
    stepPhaseMetaInfo: useMemo(() => stepPhaseMetaInfo, [stepPhaseMetaInfo]),
    onGoBack,
    changeNodeProps,
    setTargetNode,
    setInputNode,
    // companyId: companyIdValidated,
    // campaignId: campaignId
  };
};

export default useGetNodesHook;
