import { SlidersOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computationApi } from "src/api/computation.api";
import {
  ArrowTopRightOnSquareIcon,
  CloneIcon,
  DeleteIcon,
  GlobeIcon,
  Icons,
  LabelsIcon,
  Square2StackIcon,
  SquaresPlusIcon,
  StorageIcon,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { ChangeCampaignsCompanyModal } from "src/components/common/modals/ChangeCampaignsCompanyModal";
import { Button } from "src/components/common/ui/button";
import { ComputationType } from "src/constants";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useGoBack } from "src/hooks/useGoBack";
import useModals from "src/hooks/useModals";
import { publishCampaignToMicroSiteApi } from "../../../api/campaigns.api";
import { TPhaseFormValues } from "../../../globalTypes";
import useConfirm from "../../../hooks/useConfirm";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  TCampaign,
  cloneAndOpenCampaignThunk,
  deleteCampaignThunk,
  selectIsRerunCampaignEnabled,
} from "../../../store/slices/campaignsSlice";
import { getEnvVariablesWithTypesThunk } from "../../../store/slices/envVariablesSlice";
import {
  getCurrentPhase,
  getPhases,
  savePhaseThunk,
} from "../../../store/slices/phasesSlice";
import { getSteps, saveStepThunk } from "../../../store/slices/stepsSlice";
import { UserPermissions } from "../../../store/slices/usersSlice";
import { AppDispatch } from "../../../store/store";
import {
  PHASE_SEQUENCE_STEP,
  STEP_SEQUENCE_STEP,
} from "../../../utils/campaigns.constant";
import { getLastSequence } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import {
  getCampaignNameValidationRule,
  getPhaseNameValidationRule,
  getStepNameValidationRule,
} from "../../../utils/validators";
import { TStepFormValues } from "../../campaignSteps/CampaignSteps/CampaignSteps";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import PhaseModal from "../../common/modals/PhaseModal/PhaseModal";
import StepModal from "../../common/modals/StepModal/StepModal";
import EditCampaignModal from "../EditCampaignModal/EditCampaignModal";
import ExportCampaignModal from "../ExportCampaignModal/ExportCampaignModal";

export type TExportCampaignSettings = {
  I18N: boolean;
  ENV: boolean;
  CC: boolean;
  ASSETS: boolean;
  STORAGE: boolean;
};

export type TSettingType = keyof TExportCampaignSettings;

type Props = {
  companyId: number | undefined;
  currentCampaign: TCampaign;
};

const Settings: FC<Props> = ({ currentCampaign, companyId }) => {
  const { id: campaignId, name: campaignName } = currentCampaign;
  const dispatch: AppDispatch = useDispatch();
  const phases = getPhases();
  const currentPhase = getCurrentPhase();
  const steps = getSteps();
  const navigate = useNavigate();
  const goBack = useGoBack("/admin/campaigns");
  const messageApi = useSelector(selectMessageApi);
  const confirm = useConfirm();
  const { execute, modal } = useModals();
  const [addPhaseForm] = Form.useForm<TPhaseFormValues>();
  const [addStepForm] = Form.useForm<TStepFormValues>();
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isAddPhaseModalOpen, setIsAddPhaseModalOpen] = useState(false);
  const [isAddStepModalOpen, setIsAddStepModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChangeCompanyModalOpen, setIsChangeCompanyModalOpen] =
    useState(false);
  const isGlobalCampaign = companyId === undefined;
  const companyIdForUrl = isGlobalCampaign ? "global" : companyId;
  const isRerunCampaignEnabled = useSelector(selectIsRerunCampaignEnabled);

  const {
    isGlobalAdmin,
    hasPermission,
    hasPhaseCreateRole,
    hasStepCreateRole,
  } = useCurrentUser();

  const hasStorageReadRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_FS_READ);
  const hasEnvManagerRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_G_CAMPAIGN_MANAGER);
  const hasCampaignManagerRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_MANAGER);
  const hasLabelsManagerRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_C18N_MANAGER);
  const hasCampaignChangeCompanyRole =
    isGlobalAdmin ||
    hasPermission(UserPermissions.ROLE_CAMPAIGN_CHANGE_COMPANY);

  const phaseNameValidationRule = getPhaseNameValidationRule({
    editPhaseName: undefined,
    phases,
  });
  const stepNameValidationRule = getStepNameValidationRule({
    editStepName: undefined,
    steps,
  });
  const campaignNameValidationRule = getCampaignNameValidationRule();

  const onOpenVars = async () => {
    try {
      const res = await dispatch(getEnvVariablesWithTypesThunk());

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to get env variables:", e);
    }
  };

  const onDelete = async (removeFiles: boolean) => {
    try {
      await dispatch(
        deleteCampaignThunk({ campaignId: currentCampaign.id, removeFiles })
      ).unwrap();

      goBack();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to delete the campaign:",
        e
      );
    }
  };

  const onPublishCampaignToMicroSite = async () => {
    try {
      await publishCampaignToMicroSiteApi({ campaignId, companyId });

      messageApi.success(
        "The campaign has been successfully published to micro site"
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onClone = async (values: TCloneEntityFormValues) => {
    try {
      const res = await dispatch(
        cloneAndOpenCampaignThunk({
          campaignId: currentCampaign.id,
          companyId,
          params: values,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        navigate(
          `/admin/campaigns/company/${companyIdForUrl}/campaign/${res.payload}`
        );

        setIsCloneModalOpen(false);
        messageApi.success("The campaign has been successfully cloned");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to clone the campaign:", e);
    }
  };

  async function onCreatePhase(values: TPhaseFormValues) {
    try {
      const sequence = getLastSequence(phases, PHASE_SEQUENCE_STEP);

      const res = await dispatch(
        savePhaseThunk({
          phaseName: values.name,
          sequence: sequence,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        const path = `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${res.payload.id}`;

        navigate(path);
        onAddPhaseModalCancel();

        messageApi.success("The phase has been successfully created");
      }
    } catch (e: any) {
      messageApi.error(
        `An error occurred while trying to save the phase: ${e?.message}`
      );
      console.error("An error occurred while trying to save the phase:", e);
    }
  }

  const onAddPhaseModalCancel = () => {
    addPhaseForm.resetFields();
    setIsAddPhaseModalOpen(false);
  };

  const onSubmitAddPhaseForm = submitFormWithTrim({
    form: addPhaseForm,
    onSuccessValidationCb: async (values: TPhaseFormValues) => {
      await onCreatePhase(values);
    },
  });

  async function onCreateStep(values: TStepFormValues) {
    try {
      const sequence = getLastSequence(steps, STEP_SEQUENCE_STEP);

      const res = await dispatch(
        saveStepThunk({
          phaseId: currentPhase!.id,
          stepName: values.name,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
          classes: values.classes,
          sequence,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        const { phaseId, stepId } = res.payload;
        const path = `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}`;

        navigate(path);
        onAddStepModalCancel();

        messageApi.success("The step has been successfully created");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to save the step:", e);
    }
  }

  const onAddStepModalCancel = () => {
    addStepForm.resetFields();
    setIsAddStepModalOpen(false);
  };

  const onSubmitAddStepForm = submitFormWithTrim({
    form: addStepForm,
    onSuccessValidationCb: async (values: TStepFormValues) => {
      await onCreateStep(values);
    },
  });

  const settingsItems = [
    hasEnvManagerRole && {
      key: "1",
      label: "Environment variables",
      icon: SlidersOutlined,
      onClick: onOpenVars,
    },
    hasLabelsManagerRole && {
      key: "2",
      label: "Labels",
      icon: LabelsIcon,
      onClick: () =>
        navigate(
          `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/labels`
        ),
    },
    companyIdForUrl !== "global" &&
      hasStorageReadRole && {
        key: "3",
        label: "Storage",
        icon: StorageIcon,
        onClick: () =>
          navigate(
            `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/storage/public`
          ),
      },
  ].filter(Boolean) as MenuDropdownItem[];

  const actionsItems = [
    hasCampaignManagerRole && {
      key: "0",
      label: "Edit Campaign",
      icon: Icons.edit,
      onClick: () => setIsEditModalOpen(true),
    },
    hasCampaignManagerRole && {
      key: "1",
      label: "Export Campaign",
      icon: ArrowTopRightOnSquareIcon,
      onClick: () => setIsOpenExportModal(true),
    },
    hasCampaignManagerRole && {
      key: "2",
      label: "Clone Campaign",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasPhaseCreateRole && {
      key: "3",
      label: "Add Phase",
      icon: Square2StackIcon,
      onClick: () => setIsAddPhaseModalOpen(true),
    },
    hasStepCreateRole && {
      key: "4",
      label: "Add Step",
      disabled: !currentPhase,
      icon: SquaresPlusIcon,
      onClick: () => setIsAddStepModalOpen(true),
    },
    hasCampaignManagerRole &&
      companyId !== undefined && {
        label: "Micro Site Publish",
        key: "5",
        onClick: () =>
          confirm({
            action: onPublishCampaignToMicroSite,
            title: "Publish to Micro Site",
          }),
        icon: GlobeIcon,
      },
    hasCampaignChangeCompanyRole &&
      !isGlobalCampaign && {
        key: "6",
        label: "Change Company",
        icon: ArrowTopRightOnSquareIcon,
        onClick: () => setIsChangeCompanyModalOpen(true),
      },
    hasCampaignManagerRole && {
      key: "7",
      label: "Delete Campaign",
      danger: true,
      icon: DeleteIcon,
      className: "!text-red-500",
      onClick: () => setIsDeleteModalOpen(true),
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  return (
    <>
      {modal}
      <MenuDropdown items={settingsItems} dropdownClassName="w-[210px]">
        <Button
          icon={Icons.Settings}
          iconClassName="size-5"
          variant={"ghostPrimary"}
          className="text-sm font-medium"
        >
          Settings
          <Icons.More className="size-4 ml-2" />
        </Button>
      </MenuDropdown>
      {actionsItems && !!actionsItems.length && (
        <MenuDropdown items={actionsItems} dropdownClassName="w-[190px]">
          <Button variant={"primaryOutline"} className="text-sm font-medium">
            Actions
            <Icons.More className="size-4 ml-2" />
          </Button>
        </MenuDropdown>
      )}
      {(isGlobalAdmin || isRerunCampaignEnabled) && (
        <Button
          className="rounded-full"
          icon={Icons.rerun}
          iconClassName="size-5"
          onClick={() => {
            execute({
              title: "Re-Run Campaign",
              requestExecute: async (
                computationType: ComputationType,
                republish: boolean
              ) => {
                try {
                  const { data: isExecuting } = await computationApi(
                    campaignId,
                    companyId
                  ).validateCampaign();

                  if (!isExecuting) {
                    await computationApi(campaignId, companyId).executeCampaign(
                      computationType,
                      republish
                    );
                    messageApi.success("Execution started successfully");
                  } else {
                    messageApi.error(
                      "There are some active computation elements in progress, please wait before execute"
                    );
                  }
                } catch (e: any) {
                  const customError = handleRequestError(e);

                  messageApi.error(customError.message);
                  console.error(customError);
                }
              },
            });
          }}
        >
          Re-Run Campaign
        </Button>
      )}
      {isOpenExportModal && (
        <ExportCampaignModal
          companyId={companyId}
          isOpenExportModal={isOpenExportModal}
          currentCampaign={currentCampaign}
          setIsOpenExportModal={setIsOpenExportModal}
          campaignId={campaignId}
        />
      )}
      {isAddPhaseModalOpen && (
        <PhaseModal
          onCancel={onAddPhaseModalCancel}
          onSubmitForm={onSubmitAddPhaseForm}
          form={addPhaseForm}
          isModalOpen={isAddPhaseModalOpen}
          editPhase={null}
          phaseNameValidationRule={phaseNameValidationRule}
        />
      )}
      {isAddStepModalOpen && (
        <StepModal
          onSubmitForm={onSubmitAddStepForm}
          onCancel={onAddStepModalCancel}
          form={addStepForm}
          isModalOpen={isAddStepModalOpen}
          stepNameValidationRule={stepNameValidationRule}
          editStep={null}
        />
      )}
      {isCloneModalOpen && (
        <CloneEntityModal
          entity="Campaign"
          entityTitle={campaignName}
          entityTitleTooltip="Please enter a string between 4 and 255 characters."
          nameValidationRule={campaignNameValidationRule}
          onSubmitForm={onClone}
          isModalOpen={isCloneModalOpen}
          onCancel={() => setIsCloneModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Campaign"
          onDelete={onDelete}
          title={campaignName}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
      {isEditModalOpen && (
        <EditCampaignModal
          setIsModalOpen={setIsEditModalOpen}
          isModalOpen={isEditModalOpen}
          currentCampaign={currentCampaign}
          companyId={companyId}
        />
      )}
      {isChangeCompanyModalOpen && (
        <ChangeCampaignsCompanyModal
          open={isChangeCompanyModalOpen}
          setOpen={setIsChangeCompanyModalOpen}
          campaignId={campaignId}
        />
      )}
    </>
  );
};

export default Settings;
