import { Empty, Skeleton } from "antd";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteAssetApi } from "src/api/assets.api";
import AssetModal from "src/components/campaignAssets/AssetModal/AssetModal";
import UploadAssetsModal from "src/components/campaignAssets/UploadAssetsModal/UploadAssetsModal";
import { Icons } from "src/components/common/Icons";
import { MenuDropdownItem } from "src/components/common/MenuDropdown";
import { TAsset, TFileTreeItem } from "src/globalTypes";
import useConfirm from "src/hooks/useConfirm";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { getMessageApi } from "src/store/slices/appSlice";
import { TStep } from "src/store/slices/stepsSlice";
import { UserPermissions } from "src/store/slices/usersSlice";
import handleRequestError from "src/utils/handleRequestError";
import { menuOptions } from "../dynamicStorage/constants";
import FileTree from "../staticStorage/FileTree";
import { GetMenuActions } from "../staticStorage/hooks/types";
import useStaticStorage from "../staticStorage/hooks/useStaticStorage.hook";
import { convertAssetToFileTreeItem, getCampaignViewFileTree } from "./helpers";
import { AssetsFileTreeItem } from "./types";

const CampaignAssetsStorage = () => {
  const { companyId, campaignId } = useParams();
  const { isGlobalAdmin, hasPermission } = useCurrentUser();
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
  const [isUploadAssetsModalOpen, setIsUploadAssetsModalOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState<TStep | null>(null);
  const [editAssetData, setEditAssetData] = useState<TAsset | null>(null);
  const message = getMessageApi();
  const confirm = useConfirm();
  const hasStorageWriteRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_FS_WRITE);
  const hasStorageReadRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_FS_READ);

  const getFileTree = useCallback(() => {
    if (!companyId || !campaignId) {
      return Promise.resolve([]);
    }

    return getCampaignViewFileTree({
      companyId,
      campaignId,
    });
  }, [companyId, campaignId]);

  const handleGetLink = (item: AssetsFileTreeItem) => {
    if (!item.assetData?.ref) {
      return;
    }

    try {
      navigator.clipboard.writeText(item.assetData!.ref);
      message.success("Link copied to clipboard");
    } catch (e: any) {
      const customError = handleRequestError(e);

      message.error(customError.message);
      console.error(customError);
    }
  };

  const handleDeleteFile = async (
    item: AssetsFileTreeItem,
    setTree: (tree: AssetsFileTreeItem[]) => void
  ) => {
    if (!item.assetData?.id) {
      return;
    }

    confirm({
      action: async () => {
        try {
          await deleteAssetApi({
            campaignId: Number(campaignId),
            assetId: item.assetData!.id,
            companyId: Number(companyId),
          });

          setTree(
            (treeData as AssetsFileTreeItem[]).filter((treeItem) => {
              if (treeItem.assetData) {
                return treeItem.assetData.id !== item.assetData!.id;
              }

              return true;
            })
          );

          message.success("The asset has been deleted");
        } catch (e: any) {
          const customError = handleRequestError(e);

          message.error(customError.message);
          console.error(customError);
        }
      },
      title: `Delete asset "${item!.assetData!.title}"`,
    });
  };

  const handleAddAsset = (item: AssetsFileTreeItem) => {
    setIsAddAssetModalOpen(true);
    setEditAssetData(null);
    if (item.folder && item.stepData) {
      setSelectedStep(item.stepData);
    }
  };

  const handleUploadAsset = (item: AssetsFileTreeItem) => {
    setIsUploadAssetsModalOpen(true);
    if (item.stepData) {
      setSelectedStep(item.stepData);
    }
  };

  const getCampaignViewItemActions: GetMenuActions<
    TFileTreeItem | AssetsFileTreeItem
  > = ({ item, setTree }) => {
    return [
      // @ts-ignore
      item?.stepData && {
        key: "addAsset",
        label: "Add Asset",
        icon: Icons.Plus,
        onClick: () => handleAddAsset(item as AssetsFileTreeItem),
      },
      // @ts-ignore
      item?.stepData && {
        key: "uploadAsset",
        label: "Upload Asset",
        icon: Icons.UploadIcon,
        onClick: () => handleUploadAsset(item as AssetsFileTreeItem),
      },
      hasStorageReadRole &&
        !item.folder && {
          ...menuOptions.getLink,
          label:
            (item as AssetsFileTreeItem).assetData?.type === "GENERATED"
              ? "Get Public AWS Link"
              : "Get Link",
          onClick: () => handleGetLink(item as AssetsFileTreeItem),
        },
      hasStorageWriteRole &&
        !item.folder && {
          ...menuOptions.delete,
          onClick: () => handleDeleteFile(item as AssetsFileTreeItem, setTree),
        },
    ].filter(Boolean) as MenuDropdownItem[];
  };

  const {
    selectedItem,
    treeData,
    isLoading,
    error,
    handleExpandFolder,
    handleTreeDataChange,
    handleSelectedItem,
    handleUnselectItems,
  } = useStaticStorage({
    getFileTree,
    getItemMenuActions: getCampaignViewItemActions,
  });

  const handleAssetAdded = (asset: TAsset) => {
    handleTreeDataChange((treeData) => [
      ...treeData,
      convertAssetToFileTreeItem(asset, selectedStep!),
    ]);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (error) {
      return <span className="text-red-500">Error: {error}</span>;
    }

    if (treeData.length === 0) {
      return (
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <span className="font-sans font-semibold text-[14px]">
              There are no files in the storage
            </span>
          }
        />
      );
    }

    return (
      <FileTree
        onExpandFolder={handleExpandFolder}
        treeData={treeData}
        onSelectedItemChange={handleSelectedItem}
        selectedItem={selectedItem}
        getItemMenuActions={getCampaignViewItemActions}
        setTreeData={handleTreeDataChange}
      />
    );
  };

  return (
    <div
      className="w-full max-h-[600px] overflow-y-scroll mt-[24px]"
      onClick={handleUnselectItems}
    >
      {renderContent()}
      {isAddAssetModalOpen && selectedStep && (
        <AssetModal
          editAsset={editAssetData}
          onAssetCreated={handleAssetAdded}
          isModalOpen={isAddAssetModalOpen}
          setIsModalOpen={(open) => {
            setIsAddAssetModalOpen(open);
            if (!open) {
              setSelectedStep(null);
              setEditAssetData(null);
            }
          }}
          campaignId={+campaignId!}
          companyId={+companyId!}
          stepId={selectedStep!.id}
        />
      )}
      {isUploadAssetsModalOpen && selectedStep && (
        <UploadAssetsModal
          isModalOpen={isUploadAssetsModalOpen}
          setIsModalOpen={(open) => {
            setIsUploadAssetsModalOpen(open);
            if (!open) {
              setSelectedStep(null);
            }
          }}
          campaignId={+campaignId!}
          stepId={+selectedStep!.id}
          addAsset={handleAssetAdded}
        />
      )}
    </div>
  );
};

export default memo(CampaignAssetsStorage);
