import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { PostProcessingItem } from "src/components/dynamicFormItems/PostProcessing/PostProcessingItems";
import {
  createCcVarApi,
  deleteCcVarApi,
  editCcVarKeyApi,
  getCcVarsApi,
  getCcVarTypesApi,
  redeployCCItemApi,
  saveResultOvrApi,
  swapCcVarSequenceApi,
  updateCcVarStateApi,
} from "../../api/cc-variables.api";
import { FileMimeTypes } from "../../globalTypes";
import { sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";

export type TCcVariables = {
  list: Array<TCcVariable>;
  dynamicFormItems: TCcFormItems;
  varsTypes: Array<TCcVariableType>;
};

export type TCcVariableType =
  | "string"
  | "function"
  | "prompt"
  | "asset"
  | "web_scraper";

export type TComputationState =
  | "created"
  | "processing"
  | "computed"
  | "error"
  | "waiting_computation";

export type TCcVariableId = {
  campaignId: number;
  key: string;
  stepId: number;
};

export type TCcVariable = {
  id: TCcVariableId;
  seq: number;
  type: TCcVariableType;
  mimeType: FileMimeTypes;
  result: null | string;
  resultOvr: null | string;
  executable: boolean;
  supportOverride: boolean;
  options?: TCcVarsFormOptionsValues;
  state?: TComputationState;
};

export type TUpdateCcVariable = { id: TCcVariableId } & Partial<
  Omit<TCcVariable, "id">
>;

export type TCcVarsFormValues = TCcVarsFormRequiredValues &
  TCcVarsFormOptionsValues;

export type TCcVarsFormRequiredValues = {
  type: string;
  key: string;
  result: string | null;
};

export type TPromptCcVarServicePovider = ["OPENAI", "CLAUDE"];

export type TModelServiceProvider = "BEDROCK" | "VERTEX_AI" | "OPENAI";

export type TCcVarsFormOptionsValues = {
  modelName?: string;
  serviceProvider?: TPromptCcVarServicePovider;
  cacheResult?: boolean;
  scriptContent?: string;
  arguments?: Array<string>;
  postProcessingParams?: PostProcessingItem[] | string;
  cacheResponse?: boolean;
  promptKey?: null | string;
  assetTitle?: string;
  overwriteIfExists?: boolean;
  publicStorage?: boolean;
  stepId?: number;
  templateId?: string;
  microSiteTargetFolder?: string;
};

//todo add types
export type TCcDynamicFormItemsFunction = [any, any];
export type TCcDynamicFormItemsPrompt = [any, any];
export type TCcDynamicFormItemsString = Array<any>;
export type TCcDynamicFormItemsAsset = Array<any>;
export type TCcDynamicFormItemsWebScrapper = Array<any>;

export type TCcDynamicFormItems =
  | TCcDynamicFormItemsFunction
  | TCcDynamicFormItemsPrompt
  | TCcDynamicFormItemsString
  | TCcDynamicFormItemsAsset
  | TCcDynamicFormItemsWebScrapper;

export type TCcResultType = "read_only" | "modifiable";

export type TCcItemProps<
  T extends TCcResultType,
  K extends TCcDynamicFormItems,
> = {
  executable: boolean;
  resultType: T;
  items: K;
};

export type TCcFormItems = {
  function: TCcItemProps<"read_only", TCcDynamicFormItemsFunction>;
  prompt: TCcItemProps<"read_only", TCcDynamicFormItemsPrompt>;
  string: TCcItemProps<"modifiable", TCcDynamicFormItemsString>;
  asset: TCcItemProps<"modifiable", TCcDynamicFormItemsAsset>;
  web_scraper: TCcItemProps<"modifiable", TCcDynamicFormItemsWebScrapper>;
};
type InitialStateType = TCcVariables;

const initialState: InitialStateType = {
  list: [],
  dynamicFormItems: {} as TCcFormItems,
  varsTypes: [],
};

const ccVariablesSlice = createSlice({
  name: "ccVariables",
  initialState,
  reducers: {
    setCcVariables: (
      state: InitialStateType,
      action: PayloadAction<Array<TCcVariable>>
    ) => {
      state.list = action.payload;
    },
    setCcDynamicFormItems: (
      state: InitialStateType,
      action: PayloadAction<TCcFormItems>
    ) => {
      const dynamicFormItems = Object.fromEntries(
        Object.entries(action.payload).map(([key, value]) => {
          return [
            key,
            {
              ...value,
              items: value.items.map((item) => {
                if (item.type === "checkbox") {
                  return {
                    ...item,
                    defaultValue: item.defaultValue === "true",
                  };
                }
                return item;
              }),
            },
          ];
        })
      );

      state.dynamicFormItems = { ...action.payload, ...dynamicFormItems };
    },
    setCcVarsTypes: (
      state: InitialStateType,
      action: PayloadAction<Array<TCcVariableType>>
    ) => {
      state.varsTypes = action.payload;
    },
    deleteCcVariable: (
      state: InitialStateType,
      action: PayloadAction<string>
    ) => {
      state.list = state.list.filter((env) => env.id.key !== action.payload);
    },
    updateCcVariableById: (
      state: InitialStateType,
      action: PayloadAction<TUpdateCcVariable>
    ) => {
      const id = action.payload.id;
      const index = state.list.findIndex(
        (ccVar) =>
          ccVar.id.key === id.key &&
          ccVar.id.campaignId === id.campaignId &&
          ccVar.id.stepId === id.stepId
      );

      if (index !== -1) {
        state.list[index] = {
          ...state.list[index],
          ...action.payload,
        };
      }
    },
    bulkUpdateCcVariables: (
      state: InitialStateType,
      action: PayloadAction<Array<TCcVariable>>
    ) => {
      // Convert the list to an object for quick search
      const newItemsMap = action.payload.reduce(
        (acc, item) => {
          const idKey = `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`;
          acc[idKey] = item;
          return acc;
        },
        {} as Record<string, TCcVariable>
      );

      // Update existing items
      state.list = state.list.map((item) => {
        const idKey = `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`;
        if (newItemsMap[idKey]) {
          return newItemsMap[idKey];
        }
        return item;
      });

      // Add new items that were not in the list
      const existingIds = new Set(
        state.list.map(
          (item) => `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`
        )
      );
      state.list = [
        ...state.list,
        ...Object.values(newItemsMap).filter(
          (item) =>
            !existingIds.has(
              `${item.id.campaignId}-${item.id.key}-${item.id.stepId}`
            )
        ),
      ];
    },
    updateCcVariableKey: (
      state: InitialStateType,
      action: PayloadAction<{ currentKey: string; newKey: string }>
    ) => {
      const { currentKey, newKey } = action.payload;
      const index = state.list.findIndex(
        (ccVar) => ccVar.id.key === currentKey
      );

      if (index !== -1) {
        state.list[index].id.key = newKey;
      } else {
        console.error("Can't find cc var by key to update");
      }
    },
  },
});

export const {
  setCcVariables,
  setCcDynamicFormItems,
  setCcVarsTypes,
  deleteCcVariable,
  updateCcVariableById,
  updateCcVariableKey,
  bulkUpdateCcVariables,
} = ccVariablesSlice.actions;

export default ccVariablesSlice.reducer;

export const useCcVariables = (): Array<TCcVariable> =>
  useSelector((state: RootState) => state.ccVariables.list);
export const useCcDynamicFormItems = (): TCcFormItems =>
  useSelector((state: RootState) => state.ccVariables.dynamicFormItems);
export const useCcVarTypes = (): Array<TCcVariableType> =>
  useSelector((state: RootState) => state.ccVariables.varsTypes);

export type TSaveCcVarThunkProps = {
  values: TCcVarsFormValues;
  seq: number;
  action: "create" | "update";
  resetOvr: boolean;
  recomputeDownStream?: boolean;
};

export const saveCcVarThunk = createAsyncThunk<
  undefined,
  { saveVarProps: TSaveCcVarThunkProps; republish: boolean },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/createCcVar",
  async (
    { saveVarProps, republish },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));
      const { values, seq, action, resetOvr, recomputeDownStream } =
        saveVarProps;
      const { campaigns, steps, companies } = getState();
      const campaignId = campaigns.current!.id;
      const stepId = steps.current!.id;
      const companyId = companies.current?.id;

      await createCcVarApi(campaignId, values, stepId, seq, action, companyId);

      if (resetOvr) {
        await saveResultOvrApi(
          campaignId,
          values.key,
          stepId,
          "",
          companyId,
          recomputeDownStream
        );
      }

      if (republish) {
        await redeployCCItemApi({
          campaignId,
          stepId,
          key: values.key,
          companyId,
        });
      }

      const { data: ccVars } = await getCcVarsApi(
        campaignId,
        stepId,
        companyId
      );
      const sorted = sortBySequence(ccVars);

      dispatch(setCcVariables(sorted));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to create the grid item:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const deleteCcVarThunk = createAsyncThunk<
  undefined,
  { key: string; companyId: number | undefined },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/deleteCcVar",
  async ({ key, companyId }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, steps } = getState();

      await deleteCcVarApi({
        campaignId: campaigns.current!.id,
        stepId: steps.current!.id,
        key,
        companyId,
      });

      dispatch(deleteCcVariable(key));
    } catch (e: any) {
      console.error(
        `An error occurred while trying to delete the grid item:`,
        e
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const saveCcItemResultOvrThunk = createAsyncThunk<
  undefined,
  {
    varId: TCcVariableId;
    value: string;
    companyId: number | undefined;
    recomputeDownstream?: boolean;
  },
  { rejectValue: TCustomError }
>(
  "ccVariables/saveCcItemResultOvrThunk",
  async (
    { varId, value, companyId, recomputeDownstream },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));
      const { campaignId, stepId, key } = varId;
      await saveResultOvrApi(
        campaignId,
        key,
        stepId,
        value,
        companyId,
        recomputeDownstream
      );

      const { data: ccVars } = await getCcVarsApi(
        campaignId,
        stepId,
        companyId
      );
      const sorted = sortBySequence(ccVars);

      dispatch(setCcVariables(sorted));
    } catch (e: any) {
      console.error(
        `An error occurred while trying to save the result override:`,
        e
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const editCcItemKeyThunk = createAsyncThunk<
  undefined,
  { currentKey: string; newKey: string },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/editCcItemKeyThunk",
  async ({ currentKey, newKey }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { campaigns, companies, steps } = getState();
      const campaignId = campaigns.current!.id;
      const stepId = steps.current!.id;
      const companyId = companies.current?.id;

      await editCcVarKeyApi({
        campaignId,
        currentKey,
        newKey,
        stepId,
        companyId,
      });

      dispatch(updateCcVariableKey({ currentKey, newKey }));
    } catch (e: any) {
      dispatch(updateCcVariableKey({ currentKey, newKey: currentKey }));
      console.error(
        `An error occurred while trying to edit the key of the grid item:`,
        e
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    }
  }
);

export const updateCcItemStateThunk = createAsyncThunk<
  undefined,
  {
    varId: TCcVariableId;
    state: TComputationState;
    companyId: number | undefined;
  },
  { rejectValue: TCustomError }
>(
  "ccVariables/updateCcItemStateThunk",
  async ({ varId, state, companyId }, { rejectWithValue, dispatch }) => {
    try {
      await updateCcVarStateApi(
        varId.campaignId,
        varId.key,
        varId.stepId,
        state,
        companyId
      );

      const updatedItem: TUpdateCcVariable = {
        id: varId,
        state,
      };

      dispatch(updateCcVariableById(updatedItem));
    } catch (e: any) {
      console.error(
        `An error occurred while trying to update the grid item state:`,
        e
      );
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    }
  }
);

export const swapCcItemSequenceThunk = createAsyncThunk<
  undefined,
  { currentVar: TCcVariable; seqToSwap: number },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/swapCcItemSequenceThunk",
  async (
    { currentVar, seqToSwap },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, steps, companies } = getState();
      const campaignId = campaigns.current!.id;
      const stepId = steps.current!.id;
      const companyId = companies.current?.id;

      const { data: updatedCcVarsList } = await swapCcVarSequenceApi(
        campaignId,
        stepId,
        currentVar.seq,
        seqToSwap,
        companyId
      );
      const sorted = sortBySequence(updatedCcVarsList);

      dispatch(setCcVariables(sorted));
    } catch (e: any) {
      console.error(`An error occurred while trying to move the grid item:`, e);
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const loadDynamicItemsThunk = createAsyncThunk<
  void,
  { companyId: number | undefined },
  { state: RootState; rejectValue: TCustomError }
>(
  "ccVariables/swapCcItemSequenceThunk",
  async ({ companyId }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { ccVariables } = getState();

      // if types and dyn from items already loaded - skip loading
      if (!ccVariables.varsTypes.length) {
        const { data } = await getCcVarTypesApi(companyId);
        const ccVarTypes = Object.keys(data) as Array<TCcVariableType>;
        const ccDynamicFormItems = {} as TCcFormItems;

        for (const type in data) {
          // @ts-ignore
          ccDynamicFormItems[type] = data[type];
        }

        dispatch(setCcDynamicFormItems(ccDynamicFormItems));
        dispatch(setCcVarsTypes(ccVarTypes));
      }
    } catch (e: any) {
      console.error(`An error occurred while trying to move the grid item:`, e);
      const customError = handleRequestError(e);

      return rejectWithValue(customError);
    }
  }
);
